import React from 'react';
import styled from 'styled-components';
import Bolt from '@lightspeed/assets/icons/bolt.svg';
import Check from '@lightspeed/assets/icons/check.svg';
import Star from '@lightspeed/assets/icons/star.svg';
import { responsiveStyleFromTheme } from '@lightspeed/styles/utils/theme-utils';
import { IconCircle } from '@lightspeed/components/ui/atoms';

export const PurchaseRefinanceSidebarBlurb = () => (
  <div>
    <BlurbSection>
      <Blurb>
        <BlurbIconContainer>
          <IconCircle
            background={'rgba(62, 146, 247, 0.1)'}
            icon={Check}
          />
        </BlurbIconContainer>
        <BlurbText>
          All-Digital Experience
        </BlurbText>
      </Blurb>
      <Blurb>
        <BlurbIconContainer>
          <IconCircle
            background={'rgba(255, 184, 47, 0.1)'}
            icon={Bolt}
          />
        </BlurbIconContainer>
        <BlurbText>
          Quick Close
        </BlurbText>
      </Blurb>
      <Blurb>
        <BlurbIconContainer>
          <IconCircle
            background={'rgba(0, 182, 122, 0.1)'}
            icon={Star}
          />
        </BlurbIconContainer>
        <BlurbText>
          4.9 Trustpilot Rating
        </BlurbText>
      </Blurb>
    </BlurbSection>
  </div>
)

const BlurbIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BlurbSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 36px;
`;

const BlurbText = styled.h5`
  ${(props) => responsiveStyleFromTheme(props.theme.Headers.h5)};
  margin-left: 20px;
`;

const Blurb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
