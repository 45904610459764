import Cookies from 'js-cookie';

export interface ReferralCookieData {
  friends_and_family_referral_program_email?: string;
  utm_content?: string;
  utm_campaign?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_term?: string;
  gclid?: string;
  referrer?: string;
  loan_officer_token?: string;
  loan_type?: string;
  search_id?: string;
}

export function getReferralCookieData(): ReferralCookieData {
  return {
    friends_and_family_referral_program_email: Cookies.get('ffrp'),
    gclid: Cookies.get('gclid'),
    loan_officer_token: Cookies.get('lo'),
    loan_type: Cookies.get('loan_type'),
    referrer: Cookies.get('referrer'),
    search_id: Cookies.get('search_id'),
    utm_campaign: Cookies.get('utm_campaign'),
    utm_content: Cookies.get('utm_content'),
    utm_medium: Cookies.get('utm_medium'),
    utm_source: Cookies.get('utm_source'),
    utm_term: Cookies.get('utm_term'),
  };
}
