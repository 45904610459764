import { useRQWRates } from '@lightspeed/contexts/rqw-rates-context/rqw-rates-context';
import useTodaysRates from './useTodaysRates';

interface Rates {
  apr: string;
  discountPoints: string;
  loanAmount: string;
  ltv: string;
  rate: string;
  monthlyPayment: string;
};

const termToRQWRateMapping: Record<'twenty'|'thirty'|'fifteen', 'rateFor15YearTerm'|'rateFor20YearTerm'|'rateFor30YearTerm'> = {
  'fifteen': 'rateFor15YearTerm',
  'thirty': 'rateFor30YearTerm',
  'twenty': 'rateFor20YearTerm',
};

const termToRQWAPRMapping: Record<'twenty'|'thirty'|'fifteen', 'aprFor15YearTerm'|'aprFor20YearTerm'|'aprFor30YearTerm'> = {
  'fifteen': 'aprFor15YearTerm',
  'thirty': 'aprFor30YearTerm',
  'twenty': 'aprFor20YearTerm',
};

const termToRQWDiscountPointMapping: Record<'twenty'|'thirty'|'fifteen', 'discountPointsFor15YearTerm'|'discountPointsFor20YearTerm'|'discountPointsFor30YearTerm'> = {
  'fifteen': 'discountPointsFor15YearTerm',
  'thirty': 'discountPointsFor30YearTerm',
  'twenty': 'discountPointsFor20YearTerm',
};

const termToRQWMonthlyPaymentMapping: Record<'twenty'|'thirty'|'fifteen', 'monthlyPaymentFor15YearTerm'|'monthlyPaymentFor20YearTerm'|'monthlyPaymentFor30YearTerm'> = {
  'fifteen': 'monthlyPaymentFor15YearTerm',
  'thirty': 'monthlyPaymentFor30YearTerm',
  'twenty': 'monthlyPaymentFor20YearTerm',
};

const currencyFormatter = (value: string, maximumFractionDigits: number, minimumFractionDigits: number) => new Intl.NumberFormat('en-US', {
  currency: 'USD',
  maximumFractionDigits,
  minimumFractionDigits,
  style: 'currency',
}).format(parseFloat(value));

export function useRate(term: 'twenty'|'thirty'|'fifteen', type: 'purchase'|'refinance'): Rates {
  // todays rates fires a network request, there's a chance it's null in which case we render fallback
  const todaysRates = useTodaysRates();
  const rqwRates = useRQWRates();

  // If we have RQW rates, we'll use this instead.
  if (rqwRates) {
    const unformattedDiscountPoints = rqwRates[termToRQWDiscountPointMapping[term]];
    const unformattedMonthlyPayment = rqwRates[termToRQWMonthlyPaymentMapping[term]];

    return {
      apr: rqwRates[termToRQWAPRMapping[term]].toFixed(3),
      discountPoints: (Math.round(Number(unformattedDiscountPoints) * 2) / 2).toFixed(1),
      loanAmount: currencyFormatter(rqwRates.loanAmount.toFixed(0), 0, 0),
      ltv: (rqwRates.ltv * 100).toFixed(0),
      monthlyPayment: currencyFormatter(unformattedMonthlyPayment.toFixed(2), 2, 2),
      rate: rqwRates[termToRQWRateMapping[term]].toFixed(3),
    };
  }

  if (todaysRates) {
    return {
      apr: todaysRates[term][type].apr.toFixed(3),
      discountPoints: todaysRates[term][type].discountPoints.toFixed(1),
      loanAmount: currencyFormatter(todaysRates[term][type].loanAmount.toFixed(0), 0, 0),
      ltv: ((todaysRates[term][type].loanAmount / 266667) * 100).toFixed(0), // NOTE(quinton): Hardcoded value used to determine loans in daily rate calculation. Contact Ben Danis / Brand Team if you have questions
      monthlyPayment: currencyFormatter(todaysRates[term][type].payment.toFixed(2), 2, 2),
      rate: todaysRates[term][type].rate.toFixed(3),
    };
  }

  return {
    apr: '-.---',
    discountPoints: '',
    loanAmount: '',
    ltv: '',
    monthlyPayment: '',
    rate: '-.---',
  };
}
