import { useState, useCallback } from 'react';
import { CONTRACT_STATUSES } from '@lightspeed/models/contract-statuses';

export type MortgageApplicationStoreKeys = keyof MortgageApplicationStore;

export interface MortgageApplicationStore {
  borrowerAddressAutocompleteValue: string;
  borrowerFirstName: string;
  borrowerLastName: string;
  borrowerCreditAuthDateTime: string;
  borrowerDateOfBirth: string;
  borrowerEmploymentStatus: string;
  borrowerSocialSecurityNumber: string;
  borrowerEmail: string;
  borrowerPhoneNumber: string;
  borrowerYearsAtAddress: string;
  borrowerFirstTimeHomeBuyer: string;
  borrowerStreetAddress: string;
  borrowerCity: string;
  borrowerContractStatus: CONTRACT_STATUSES | '';
  borrowerState: string;
  borrowerCounty: string;
  borrowerZipCode: string;
  borrowerEmployer:string;
  borrowerYearsAtCompany:string;
  borrowerTitle:string;
  borrowerYearlySalary:string;
  borrowerOtherIncomePerYear:string;
  borrowerAmountInSavings:string;
  borrowerAmountInMutualFunds: string;
  coBorrowerAddressAutocompleteValue: string;
  coBorrowerEmployer:string;
  coBorrowerYearsAtCompany:string;
  coBorrowerTitle:string;
  coBorrowerYearlySalary:string;
  coBorrowerOtherIncomePerYear:string;
  coBorrowerAmountInSavings:string;
  coBorrowerFirstName: string;
  coBorrowerLastName: string;
  coBorrowerCreditAuthDateTime: string;
  coBorrowerDateOfBirth: string;
  coBorrowerEmploymentStatus: string;
  coBorrowerSocialSecurityNumber: string;
  coBorrowerEmail: string;
  coBorrowerAmountInMutualFunds: string;
  coBorrowerPhoneNumber: string;
  coBorrowerFirstTimeHomeBuyer: string;
  coBorrowerStreetAddress: string;
  coBorrowerCity: string;
  coBorrowerState: string;
  coBorrowerCounty: string;
  coBorrowerZipCode: string;
  discountPoints: string;
  fromRateQuery: string;
  leadId: string;
  loanTerm: 'twenty'|'thirty'|'fifteen';
  propertyAutocompleteValue: string;
  propertyPurchasePrice: string;
  propertyDownPayment: string;
  propertyDownPaymentPartiallyGift: string;
  propertyResidenceType: string;
  propertyType: string;
  propertyStreetAddress: string;
  propertyCity: string;
  propertyState: string;
  propertyCounty: string;
  propertyZipCode: string;
  propertyHomeValue: string;
  propertyMortgageBalance: string;
  propertyCashOut: string;
  applicationType: 'Purchase' | 'Refinance' | 'Heloc';
  reasonForRefinance: 'lowerPayment' | 'cashOut' | 'lessInterest' | '';
};

export interface MortgageApplicationState {
  mortgageApplication: MortgageApplicationStore,
  updateMortgageApplication: (key: MortgageApplicationStoreKeys | Partial<MortgageApplicationStore>, value?: string) => void
}

const initialApplication: MortgageApplicationStore = {
  applicationType: 'Purchase',
  borrowerAddressAutocompleteValue: '',
  borrowerAmountInMutualFunds: '',
  borrowerAmountInSavings: '',
  borrowerCity: '',
  borrowerContractStatus: '',
  borrowerCounty: '',
  borrowerCreditAuthDateTime: '', // DateTime ISO-8601 string
  borrowerDateOfBirth: '',
  borrowerEmail: '',
  borrowerEmployer: '',
  borrowerEmploymentStatus: '',
  borrowerFirstName: '',
  borrowerFirstTimeHomeBuyer: '',
  borrowerLastName: '',
  borrowerOtherIncomePerYear: '',
  borrowerPhoneNumber: '',
  borrowerSocialSecurityNumber: '',
  borrowerState: '',
  borrowerStreetAddress: '',
  borrowerTitle: '',
  borrowerYearlySalary: '',
  borrowerYearsAtAddress: '',
  borrowerYearsAtCompany: '',
  borrowerZipCode: '',
  coBorrowerAddressAutocompleteValue: '',
  coBorrowerAmountInMutualFunds: '',
  coBorrowerAmountInSavings: '',
  coBorrowerCity: '',
  coBorrowerCounty: '',
  coBorrowerCreditAuthDateTime: '', // DateTime ISO-8601 string
  coBorrowerDateOfBirth: '',
  coBorrowerEmail: '',
  coBorrowerEmployer: '',
  coBorrowerEmploymentStatus: '',
  coBorrowerFirstName: '',
  coBorrowerFirstTimeHomeBuyer: '',
  coBorrowerLastName: '',
  coBorrowerOtherIncomePerYear: '',
  coBorrowerPhoneNumber: '',
  coBorrowerSocialSecurityNumber: '',
  coBorrowerState: '',
  coBorrowerStreetAddress: '',
  coBorrowerTitle: '',
  coBorrowerYearlySalary: '',
  coBorrowerYearsAtCompany: '',
  coBorrowerZipCode: '',
  discountPoints: '',
  fromRateQuery: 'false',
  leadId: '',
  loanTerm: 'thirty',
  propertyAutocompleteValue: '',
  propertyCashOut: '',
  propertyCity: '',
  propertyCounty: '',
  propertyDownPayment: '',
  propertyDownPaymentPartiallyGift: '',
  propertyHomeValue: '',
  propertyMortgageBalance: '',
  propertyPurchasePrice: '',
  propertyResidenceType: '',
  propertyState: '',
  propertyStreetAddress: '',
  propertyType: '',
  propertyZipCode: '',
  reasonForRefinance: '',
};

export function useMortgageApplicationState(overrides: Partial<MortgageApplicationStore> = {}) {
  const [state, setState] = useState<MortgageApplicationStore>({
    ...initialApplication, ...overrides,
  });

  const update = useCallback((keyOrState: MortgageApplicationStoreKeys | Partial<MortgageApplicationStore>, value?: string) => {
    if (typeof keyOrState === 'string') {
      const key = keyOrState;
      setState((currentState) => ({
        ...currentState,
        [key]: value,
      }));
    } else if (typeof keyOrState === 'object') {
      const updatedState = keyOrState;
      setState((currentState) => ({
        ...currentState,
        ...updatedState,
      }))
    } else {
      throw new Error(`Unsupported keyOrState type passed to updateMortgageApplication. Expected string or object, found ${typeof keyOrState}`);
    }
  }, [setState]);

  return {
    mortgageApplication: state,
    updateMortgageApplication: update,
  };
}
