export const LowerLogoMobile = () => (
  <svg
    width={'22'}
    height={'23'}
    viewBox={'0 0 22 23'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={'M21.2745 13.9767C21.2745 15.5687 20.8138 17.1248 19.9506 18.4484C19.0874 19.772 17.8605 20.8036 16.4252 21.4126C14.9898 22.0216 13.4104 22.1808 11.8867 21.8699C10.3631 21.559 8.96365 20.7921 7.8654 19.6662C6.76715 18.5402 6.01944 17.1058 5.71684 15.5443C5.41424 13.9829 5.57035 12.3645 6.16541 10.894C6.76047 9.42347 7.76776 8.16681 9.05988 7.28296C10.352 6.39911 11.8709 5.92778 13.4244 5.92859C15.5053 5.93575 17.499 6.78598 18.9704 8.29375C20.4418 9.80151 21.2715 11.8444 21.2785 13.9767'}
      fill={'#001CDB'}
    />
    <path
      d={'M3.47743 0.522461H0V17.9709H3.47743V0.522461Z'}
      fill={'#1D1D23'}
    />
  </svg>
)
