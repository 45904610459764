import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { MortgageApplicationStore } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { usePersonalizedLoan } from '@lightspeed/contexts/personalized-loan-context/personalized-loan-context';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import bestavailable, { IndividualPersonalizedLoan } from '@lightspeed/api/services/personalizedLoanProducts/bestavailable';
import { transformApplicationToLoanProductsPayload } from '@lightspeed/transformers/loan-products-transformer';
import { PersonalizedLoanStore } from '@lightspeed/contexts/personalized-loan-context/use-personalized-loan-state';
import { appInsights } from '@lightspeed/utils/app-insights';
import { TERM_MAP } from '@lightspeed/utils/get-closest-loan-product';
import { ROUTES } from '@lightspeed/routing/routing-machine';
import { useAnalytics } from '@lightspeed/hooks/useAnalytics/useAnalytics';
import { useApplicationLead } from '@lightspeed/hooks/useApplicationLead/useApplicationLead';
import { useProgress } from './use-progress';
import { ProcessingTemplate } from './processing-template';

const textPrompts = [
  'Plugging in your numbers...',
  'Uploading income...',
  'Checking assets...',
  'Confirming identity...',
  'Running soft credit pull...',
  'Building rate (Exciting!)',
];

interface GetPersonalizedLoansProps {
  completeLead: (selectedLoan?: IndividualPersonalizedLoan | undefined) => Promise<Response>;
  fireAnalyticsEvent: (eventName: string, eventMetaData?: object) => void;
  mortgageApplication: MortgageApplicationStore;
  updatePersonalizedLoan: (updatedState: Partial<PersonalizedLoanStore>) => void;
  navigate: (v: string) => void;
}

const getLoanProducts = async (updatePersonalizedLoan:  (updatedState: Partial<PersonalizedLoanStore>) => void, mortgageApplication: MortgageApplicationStore) => {
  const bestAvailablePayload = transformApplicationToLoanProductsPayload(mortgageApplication, mortgageApplication.applicationType);

  const response = await bestavailable(bestAvailablePayload);
  const result = await response.json();

  updatePersonalizedLoan(result);

  if (result.loanProducts && result.loanProducts.length > 0) {
    return result.loanProducts;
  }

  throw new Error('No loan products.');
}

const completePartialLead = async (completeLead: (selectedLoan?: IndividualPersonalizedLoan | undefined) => Promise<Response>, fireAnalyticsEvent: (eventName: string, eventMetaData?: object) => void) => {
  try {
    await completeLead();
    fireAnalyticsEvent('completed_partial_lead');
  } catch (e) {
    appInsights.trackException({
      exception: e as Error,
    });
    throw new Error('Complete Partial Lead Failed');
  }
}

const getPersonalizedLoans = async ({
  completeLead,
  fireAnalyticsEvent,
  mortgageApplication,
  updatePersonalizedLoan,
  navigate,
}: GetPersonalizedLoansProps) => {
  try {
    return await getLoanProducts(updatePersonalizedLoan, mortgageApplication);
  } catch {
    try {
      await completePartialLead(completeLead, fireAnalyticsEvent);
    } catch (e) {
      appInsights.trackException({
        exception: new Error('On Processing Rates, both bestavailable and submit lead endpoints failed.'),
      });
      navigate(ROUTES.ERROR);
    }
  }
  return [];
}

export function ProcessingRates() {
  const navigate = useNavigate();
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const { personalizedLoanOptions, updatePersonalizedLoan } = usePersonalizedLoan();
  const [hasCompleted, setHasCompleted] = useState(false);
  const goToNextRoute = useNextRoute(mortgageApplication, personalizedLoanOptions);
  const fireAnalyticsEvent = useAnalytics('v2/processing_rates');
  const { completeLead } = useApplicationLead();

  useEffect(() => {
    if (hasCompleted) {
      goToNextRoute();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCompleted]);

  const asyncAction = async () => {
    const loanProducts = await getPersonalizedLoans({
      completeLead,
      fireAnalyticsEvent,
      mortgageApplication,
      navigate,
      updatePersonalizedLoan,
    });

    if (loanProducts.length === 0) {
      fireAnalyticsEvent('no_loan_products');
      return;
    }

    fireAnalyticsEvent('received_loan_products');

    const loanProductExistsForSelectedTerm =
      loanProducts.some((lp: IndividualPersonalizedLoan) => lp.loanTerm === TERM_MAP[mortgageApplication.loanTerm]);

    // rare case where no loan product is returned for the currently selected term. not sure this will happen in production.
    if (!loanProductExistsForSelectedTerm) {
      const firstThirtyYearLoanProduct = loanProducts.filter((lp: IndividualPersonalizedLoan) => lp.loanTerm === 'Thirty')[0];
      if (firstThirtyYearLoanProduct) {
        updateMortgageApplication('loanTerm', 'thirty');
        return;
      }
      const firstFifteenYearLoanProduct = loanProducts.filter((lp: IndividualPersonalizedLoan) => lp.loanTerm === 'Fiteen')[0];
      if (firstFifteenYearLoanProduct) {
        updateMortgageApplication('loanTerm', 'fifteen');
        return;
      }
      const firstTwentyYearLoanProduct = loanProducts.filter((lp: IndividualPersonalizedLoan) => lp.loanTerm === 'Twenty')[0];
      if (firstTwentyYearLoanProduct) {
        updateMortgageApplication('loanTerm', 'twenty');
      }
    }
  };

  const completeAsyncAction = useCallback(() => {
    setHasCompleted(true);
  }, [setHasCompleted]);

  const [progress, prompt] = useProgress(
    textPrompts,
    asyncAction,
    completeAsyncAction,
    15,
  );

  return (
    <ProcessingTemplate
      progress={progress}
      prompt={prompt}
    />
  );
}
