import React, { useState } from 'react';
import { TodaysRates } from '@lightspeed/components/ui/pages/v2/get-started/basic-info/todays-rates';
import { Heading, Button, OptionalElement, Label } from '@lightspeed/components/ui/atoms';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { Page } from '@lightspeed/components/ui/templates/base-page-template/page';
import { SplitSection } from '@lightspeed/components/ui/templates/split-section';
import { Form } from '@lightspeed/components/ui/templates/form/form';
import { TermSelect } from '@lightspeed/components/shared/terms-select/terms-select';
import { BasePageTemplate } from '@lightspeed/components/ui/templates';
import { MortgageApplicationStore, MortgageApplicationStoreKeys } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { useValidation } from '@lightspeed/hooks/useValidation/useValidation';
import { getButtonDisabledState } from '@lightspeed/utils/get-button-disabled-state';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { FullPropertyAddressForm } from '@lightspeed/components/ui/pages/v2/property-purchase/full-property-address-form';
import { RateDisclaimer } from '@lightspeed/components/shared/legal-disclaimers/rate-disclaimer';
import { useFireBadStateGAEvent } from '@lightspeed/hooks/useFireBadStateGAEvent/useFireBadStateGAEvent';
import { useMortgageContextDefault } from '@lightspeed/hooks/useMortgageContextDefaults/useMortgageContextDefaults';
import { useApplicationLead } from '@lightspeed/hooks/useApplicationLead/useApplicationLead';
import { LoadingModal } from '@lightspeed/components/ui/molecules';
import { HelocSidebarBlurb } from '@lightspeed/components/shared/heloc-sidebar-blurb/heloc-sidebar-blurb';
import { AddressAutocomplete, AddressAutocompleteOnChange, AddressAutocompleteWrapper, formatFeatureToString } from '@lightspeed/components/ui/atoms/address-autocomplete/address-autocomplete';
import { ValidationError } from '@lightspeed/components/ui/molecules/validation-error/validation-error';
import { allPropertiesExist } from '@lightspeed/utils/type-assertions';
import { useAnalytics } from '@lightspeed/hooks/useAnalytics/useAnalytics';
import {
  mortgageApplicationSchema,
} from '@lightspeed/contexts/mortgage-application-context/mortage-application-validation';
import { PropertyDetailsRefinanceForm } from './property-details-refinance-form';

const consistentValidationKeys: MortgageApplicationStoreKeys[] = [
  'propertyHomeValue',
  'propertyMortgageBalance',
  'propertyCashOut',
  'propertyResidenceType',
  'propertyType',
  'propertyStreetAddress',
  'propertyCity',
  'propertyState',
  'propertyZipCode',
];

const compareAddresses = (mortgageApplication: MortgageApplicationStore) => {
  const {
    borrowerStreetAddress, borrowerCity, borrowerCounty, borrowerState, borrowerZipCode, propertyStreetAddress, propertyCity, propertyState, propertyCounty, propertyZipCode,
  } = mortgageApplication;

  const streetAddressIsSame = borrowerStreetAddress === propertyStreetAddress;
  const cityIsSame = borrowerCity === propertyCity;
  const countyIsSame = borrowerCounty === propertyCounty;
  const stateIsSame = borrowerState === propertyState;
  const zipCodeIsSame = borrowerZipCode === propertyZipCode;

  return streetAddressIsSame && cityIsSame && countyIsSame && stateIsSame && zipCodeIsSame;
};

const setPropertyAddressToCurrentAddress = (mortgageApplication: MortgageApplicationStore, updateMortgageApplication: (key: keyof MortgageApplicationStore, value: string) => void) => {
  updateMortgageApplication('propertyStreetAddress', mortgageApplication.borrowerStreetAddress);
  updateMortgageApplication('propertyCity', mortgageApplication.borrowerCity);
  updateMortgageApplication('propertyState', mortgageApplication.borrowerState);
  updateMortgageApplication('propertyCounty', mortgageApplication.borrowerCounty);
  updateMortgageApplication('propertyZipCode', mortgageApplication.borrowerZipCode);
};

export function PropertyNonPurchase() {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const propertyAddressIsSameAsBorrowerAddress = compareAddresses(mortgageApplication);
  const goToNextRoute = useNextRoute(mortgageApplication);
  const fireAnalyticsEvent = useAnalytics('v2/property_non_purchase');
  const { upsertLead } = useApplicationLead();
  const [showLoadingModal, setShowLoadingModal] = useState(false);

  useMortgageContextDefault('propertyCashOut');

  const [validate, errors] = useValidation(mortgageApplicationSchema, {
    keysToValidate: consistentValidationKeys,
  });

  useFireBadStateGAEvent(mortgageApplication, errors, fireAnalyticsEvent);

  const disableNextButton = getButtonDisabledState<MortgageApplicationStore>(
    mortgageApplication,
    consistentValidationKeys,
  );

  const onNextClick = () => {
    (async () => {
      const validationResult = validate(mortgageApplication);

      if (validationResult.success) {
        setShowLoadingModal(true);
        try {
          await upsertLead();
          setShowLoadingModal(false);
        } catch (e) {
          // do nothing
        }

        fireAnalyticsEvent('next_button_success');
        goToNextRoute();
      }
    })();
  };

  const onAutocompleteChange: AddressAutocompleteOnChange = (argument) => {
    if (argument && allPropertiesExist(argument)) {
      updateMortgageApplication('propertyAutocompleteValue', formatFeatureToString(argument));
      updateMortgageApplication('propertyStreetAddress', `${argument.streetNumber} ${argument.streetName}`);
      updateMortgageApplication('propertyCity', `${argument.city}`);
      updateMortgageApplication('propertyState', `${argument.state}`);
      updateMortgageApplication('propertyZipCode', `${argument.zipcode}`);
      updateMortgageApplication('propertyCounty', `${argument.county}`);
    } else {
      updateMortgageApplication('propertyAutocompleteValue', '');
      updateMortgageApplication('propertyStreetAddress', '');
      updateMortgageApplication('propertyCity', '');
      updateMortgageApplication('propertyState', '');
      updateMortgageApplication('propertyZipCode', '');
      updateMortgageApplication('propertyCounty', '');
    }
  };

  return (
    <>
      <OptionalElement show={showLoadingModal}>
        <LoadingModal />
      </OptionalElement>
      <Page
        desktop={(
          <>
            <Header />
            <BasePageTemplate>
              <SplitSection>
                <SplitSection.Content>
                  <Heading element={'h1'}>
                    The property.
                  </Heading>

                  <Form>
                    <Heading
                      element={'h4'}
                    >
                      Property Details
                    </Heading>

                    <PropertyDetailsRefinanceForm
                      propertyAddressIsSameAsBorrowerAddress={propertyAddressIsSameAsBorrowerAddress}
                      errors={errors}
                      fireAnalyticsEvent={fireAnalyticsEvent}
                      mortgageApplication={mortgageApplication}
                      updateMortgageApplication={updateMortgageApplication}
                      setPropertyAddressToCurrentAddress={setPropertyAddressToCurrentAddress}
                    />

                    <OptionalElement show={!propertyAddressIsSameAsBorrowerAddress}>
                      <AddressAutocomplete
                        id={'autocomplete-property'}
                        initialSearchText={mortgageApplication.propertyAutocompleteValue}
                        initialShowFallback={mortgageApplication.propertyAutocompleteValue === '' && !!mortgageApplication.propertyState}
                        onChange={onAutocompleteChange}
                        WrappingElement={AddressAutocompleteWrapper}
                        placeholder={'123 Apple Creek Ln. Columbus, OH 43004'}
                        fireAnalyticsEvent={fireAnalyticsEvent}
                        fallbackUi={(
                          <FullPropertyAddressForm
                            mortgageApplication={mortgageApplication}
                            updateMortgageApplication={updateMortgageApplication}
                            errors={errors}
                          />
                        )}
                      />
                      <OptionalElement show={!!mortgageApplication.propertyAutocompleteValue}>
                        <ValidationError
                          path={'propertyState'}
                          errors={errors}
                        />
                      </OptionalElement>
                    </OptionalElement>
                  </Form>
                </SplitSection.Content>
                <SplitSection.Sidebar>
                  <OptionalElement show={mortgageApplication.applicationType !== 'Heloc'}>
                    <TodaysRates
                      mortgageApplication={mortgageApplication}
                    />
                    <Label marginBottom={'8px'}>
                      Loan Term
                    </Label>
                    <TermSelect
                      value={mortgageApplication.loanTerm}
                      onChange={(v) => {
                        updateMortgageApplication('loanTerm', v);
                      }}
                    />
                  </OptionalElement>
                  <OptionalElement show={mortgageApplication.applicationType === 'Heloc'}>
                    <HelocSidebarBlurb />
                  </OptionalElement>
                  <Button
                    marginY={'32px'}
                    disabled={disableNextButton}
                    onClick={onNextClick}
                  >
                    Next
                  </Button>
                  <RateDisclaimer />
                </SplitSection.Sidebar>
              </SplitSection>
            </BasePageTemplate>
          </>
        )}
        mobile={(
          <>
            <Header />
            <BasePageTemplate>
              <Heading element={'h1'}>
                The property.
              </Heading>

              <Form>
                <Heading
                  element={'h4'}
                >
                  Property Details
                </Heading>

                <PropertyDetailsRefinanceForm
                  propertyAddressIsSameAsBorrowerAddress={propertyAddressIsSameAsBorrowerAddress}
                  errors={errors}
                  fireAnalyticsEvent={fireAnalyticsEvent}
                  mortgageApplication={mortgageApplication}
                  updateMortgageApplication={updateMortgageApplication}
                  setPropertyAddressToCurrentAddress={setPropertyAddressToCurrentAddress}
                />

                <OptionalElement show={!propertyAddressIsSameAsBorrowerAddress}>
                  <AddressAutocomplete
                    fireAnalyticsEvent={fireAnalyticsEvent}
                    id={'autocomplete-property'}
                    initialSearchText={mortgageApplication.propertyAutocompleteValue}
                    initialShowFallback={mortgageApplication.propertyAutocompleteValue === '' && !!mortgageApplication.propertyState}
                    onChange={onAutocompleteChange}
                    WrappingElement={AddressAutocompleteWrapper}
                    placeholder={'123 Apple Creek Ln. Columbus, OH 43004'}
                    fallbackUi={(
                      <FullPropertyAddressForm
                        mortgageApplication={mortgageApplication}
                        updateMortgageApplication={updateMortgageApplication}
                        errors={errors}
                      />
                    )}
                  />
                  <OptionalElement show={!!mortgageApplication.propertyAutocompleteValue}>
                    <ValidationError
                      path={'propertyState'}
                      errors={errors}
                    />
                  </OptionalElement>
                </OptionalElement>
              </Form>

              <Button
                marginTop={'32px'}
                marginBottom={'32px'}
                disabled={disableNextButton}
                onClick={onNextClick}
              >
                Next
              </Button>
            </BasePageTemplate>
          </>
        )}
      />
    </>
  );
}
