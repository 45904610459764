import styled from 'styled-components';
import cx from 'classnames';
import React, { forwardRef } from 'react';
import { TypographyTokens } from '@lightspeed/styles/primary/tokens/typography';

export interface TextInputProps extends React.ComponentProps<'input'> {
  testId?: string;
  hasError?: boolean;
}

const BaseTextInput = forwardRef(({
  hasError,
  className,
  type = 'text',
  ...props
}: TextInputProps, ref: React.ForwardedRef<HTMLInputElement>) => (
  <input
    type={type}
    className={
      cx(className, {
        'has-error': hasError,
        'is-disabled': props.disabled,
      })
    }
    ref={ref}
    {...props}
  />
));

export const TextInput = styled(BaseTextInput)`
  font-family: ${ TypographyTokens.Gotham.book };
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  height: 16px;
  letter-spacing: -0.04em;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 20px;
  width: 100%;

  background: #FFFFFF;
  border: 1px solid #E5E6EE;
  box-sizing: border-box;
  border-radius: 12px;

  transition: border ${ (props) => props.theme.transitions.prop };

  // firefox specific padding for date input
  @-moz-document url-prefix() {
    &[type='date'] {
      padding: 15px 24px 33px 24px;
    }
  }

  &.has-error {
    border: 1px solid ${ (props) => props.theme.colors.error };
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1 + */
    color: #C4C6D1;
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10 - 11 */
    color: #C4C6D1;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #C4C6D1;
  }

  &:hover {
  }

  &:disabled {
  }
`

