import { v4 as uuidv4 } from 'uuid';

type localKeys = 'borrowerUUID'|'coborrowerUUID';

/**
 * We need to store UUIDs in local storage for borrower and coborrower to
 * allow the server to dedupe calls. This function accomplishes that.
 */
export function getLocalUuid(key: localKeys) {
  const storedValue = sessionStorage.getItem(key) || uuidv4();
  sessionStorage.setItem(key, storedValue);

  return storedValue;
}
