import * as yup from 'yup';
import { STATE_OPTIONS, UNSUPPORTED_STATES } from '@lightspeed/models/us-states';
import { CONTRACT_STATUSES } from '@lightspeed/models/contract-statuses';
import { EmploymentStatus } from '@lightspeed/types/income';
import { coborrowerEmploymentStatuses, employmentStatuses } from '@lightspeed/models/employment-status';

const today = new Date();

export const mortgageApplicationSchema = yup.object().shape({
  borrowerAmountInMutualFunds: yup.number().min(0).label('Amount in mutual funds')
    .when('applicationType', (applicationType: string, schema) => (applicationType === 'purchase' ? schema.required() : schema)),

  borrowerAmountInSavings: yup.number().min(1).label('Amount in savings')
    .when('applicationType', (applicationType: string, schema) => (applicationType === 'purchase' ? schema.required() : schema)),

  borrowerCity: yup.string().required().label('City'),

  borrowerContractStatus: yup.string().oneOf(Object.values(CONTRACT_STATUSES), 'Contract Status is required')
    .when('applicationType', (applicationType: string, schema) => (applicationType === 'purchase' ? schema.required() : schema)),

  borrowerCounty: yup.string().required().label('County'),

  borrowerDateOfBirth: yup.date().required().typeError('ex: 03/09/1994')
    .min(new Date('01/01/1885'), 'Must be less than 115 years old')
    .max(new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()), 'Must be 18 years or older')
    .label('Date of Birth'),

  borrowerEmail: yup.string().email().required().label('Email'),

  borrowerEmployer: yup.string().required().label('Employer name'),

  borrowerEmploymentStatus: yup.string().oneOf(employmentStatuses,  'Employment Status is required').required(),

  borrowerFirstName: yup.string().required().label('First name'),

  borrowerFirstTimeHomeBuyer: yup.string().oneOf(['Yes', 'No'])
    .when('applicationType', (applicationType: string, schema) => (applicationType === 'purchase' ? schema.required() : schema)),

  borrowerLastName: yup.string().required().label('Last name'),

  borrowerOtherIncomePerYear: yup.number().min(0).required().label('Other income per year')
    .when('borrowerYearlySalary', {
      is: (salary: string) => salary === '' || parseInt(salary, 10) < 1,
      then: yup.number().min(1).required().label('Combined yearly income'),
    }),

  borrowerPhoneNumber: yup.string().matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'requires valid phone number (e.g. 999-999-9999)').required().label('Phone Number'),

  borrowerSocialSecurityNumber: yup.string().matches(/^\d{3}-?\d{2}-?\d{4}$/,  'requires valid social security number').required().label('Social Security Number'),

  borrowerState: yup.string().oneOf(STATE_OPTIONS.map((kvp) => kvp.value), 'State is required').required().label('State'),

  borrowerStreetAddress: yup.string().required().label('Street address'),

  borrowerTitle: yup.string().required().label('Title'),

  borrowerYearlySalary: yup.number().min(0).required().label('Annual Gross Salary')
    .when('borrowerOtherIncomePerYear', {
      is: (otherIncome: string) => otherIncome === '' || parseInt(otherIncome, 10) < 1,
      then: yup.number().min(1).required().label('Combined yearly income'),
    }),

  borrowerYearsAtAddress: yup.number().min(0).max(99, 'Years at Current Address must be less than or equal to 99 years').required().typeError('Years at Current Address is required'),

  borrowerYearsAtCompany: yup.number().min(0).max(99, 'Years at company must be less than or equal to 99 years').required().label('Years at company'),

  borrowerZipCode: yup.string().matches(/^\d{5}$/g, 'requires valid zip code').required().label('Zip code'),

  coBorrowerAmountInMutualFunds: yup.number().nullable().transform((curr, orig) => (orig === '' ? null : curr)) // transform required to handle default value of '' even when optional
    .when(['coBorrowerFirstName'], {
      is: (coBorrowerFirstName: string) => coBorrowerFirstName !== '',
      then: yup.number().min(0).required(),
    }),

  coBorrowerAmountInSavings: yup.number().nullable().transform((curr, orig) => (orig === '' ? null : curr)) // transform required to handle default value of '' even when optional
    .when(['coBorrowerFirstName'], {
      is: (coBorrowerFirstName: string) => coBorrowerFirstName !== '',
      then: yup.number().min(0).required(),
    }),

  coBorrowerCity: yup.string()
    .when(['$hasCoBorrower', '$hasDifferentCoBorrowerAddress'], {
      is: (hasCoBorrower: boolean, hasDifferentCoBorrowerAddress: boolean) => (hasCoBorrower && hasDifferentCoBorrowerAddress),
      then: yup.string().label('City').required(),
    }),

  coBorrowerCounty: yup.string()
    .when(['$hasCoBorrower', '$hasDifferentCoBorrowerAddress'], {
      is: (hasCoBorrower: boolean, hasDifferentCoBorrowerAddress: boolean) => (hasCoBorrower && hasDifferentCoBorrowerAddress),
      then: yup.string().label('County').required(),
    }),

  coBorrowerDateOfBirth: yup.date().nullable().transform((curr, orig) => (orig === '' ? null : curr)) // transform required to handle default value of '' even when optional
    .when(['$hasCoBorrower'], {
      is: (hasCoBorrower: boolean) => (hasCoBorrower),
      then: yup.date().required().typeError('ex: 03/09/1994')
        .min(new Date('01/01/1885'), 'Must be less than 115 years old')
        .max(new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()), 'Must be 18 years or older')
        .label('Date of Birth'),
    }),

  coBorrowerEmail: yup.string()
    .when(['$hasCoBorrower'], {
      is: (hasCoBorrower: boolean) => (hasCoBorrower),
      then: yup.string().email().label('Email').required(),
    }),

  coBorrowerEmployer: yup.string()
    .when(['coBorrowerFirstName', 'coBorrowerEmploymentStatus'], {
      is: (coBorrowerFirstName: string, employmentStatus: string) =>
        coBorrowerFirstName !== ''
        && employmentStatus !== EmploymentStatus.UNEMPLOYED,
      then: yup.string().required(),
    }),

  coBorrowerEmploymentStatus: yup.string()
    .when(['$hasCoBorrower'], {
      is: (hasCoBorrower: boolean) => (hasCoBorrower),
      then: yup.string().oneOf(coborrowerEmploymentStatuses, 'Employment Status is required').required(),
    }),

  coBorrowerFirstName: yup.string()
    .when(['$hasCoBorrower'], {
      is: (hasCoBorrower: boolean) => (hasCoBorrower),
      then: yup.string().label('First Name').required(),
    }),

  coBorrowerLastName: yup.string()
    .when(['$hasCoBorrower'], {
      is: (hasCoBorrower: boolean) => (hasCoBorrower),
      then: yup.string().label('Last Name').required(),
    }),

  coBorrowerOtherIncomePerYear: yup.number().nullable().transform((curr, orig) => (orig === '' ? null : curr)) // transform required to handle default value of '' even when optional
    .when(['coBorrowerFirstName'], {
      is: (coBorrowerFirstName: string) => coBorrowerFirstName !== '',
      then: yup.number().min(0).required(),
    }),

  coBorrowerPhoneNumber: yup.string()
    .when(['$hasCoBorrower'], {
      is: (hasCoBorrower: boolean) => (hasCoBorrower),
      then: yup.string().matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'requires valid phone number (e.g. 999-999-9999)').label('Phone Number').required(),
    }),

  coBorrowerSocialSecurityNumber: yup.string()
    .when(['$hasCoBorrower'], {
      is: (hasCoBorrower: boolean) => (hasCoBorrower),
      then: yup.string().matches(/^\d{3}-?\d{2}-?\d{4}$/,  'requires valid social security number').label('Social Security Number').required(),
    }),

  coBorrowerState: yup.string()
    .when(['$hasCoBorrower', '$hasDifferentCoBorrowerAddress'], {
      is: (hasCoBorrower: boolean, hasDifferentCoBorrowerAddress: boolean) => (hasCoBorrower && hasDifferentCoBorrowerAddress),
      then: yup.string().oneOf(STATE_OPTIONS.map((kvp) => kvp.value), 'State is required').required(),
    }),

  coBorrowerStreetAddress: yup.string()
    .when(['$hasCoBorrower', '$hasDifferentCoBorrowerAddress'], {
      is: (hasCoBorrower: boolean, hasDifferentCoBorrowerAddress: boolean) => (hasCoBorrower && hasDifferentCoBorrowerAddress),
      then: yup.string().label('Street Address').required(),
    }),

  coBorrowerTitle: yup.string()
    .when(['coBorrowerFirstName', 'coBorrowerEmploymentStatus'], {
      is: (coBorrowerFirstName: string, employmentStatus: string) => coBorrowerFirstName !== '' && employmentStatus !== EmploymentStatus.UNEMPLOYED,
      then: yup.string().label('Title').required(),
    }),

  coBorrowerYearlySalary: yup.number().nullable().transform((curr, orig) => (orig === '' ? null : curr)) // transform required to handle default value of '' even when optional
    .when(['coBorrowerFirstName'], {
      is: (coBorrowerFirstName: string) => coBorrowerFirstName !== '',
      then: yup.number().min(0).required(),
    }),

  coBorrowerYearsAtCompany: yup.number().nullable().transform((curr, orig) => (orig === '' ? null : curr)) // transform required to handle default value of '' even when optional
    .when(['coBorrowerFirstName', 'coBorrowerEmploymentStatus'], {
      is: (coBorrowerFirstName: string, employmentStatus: string) => coBorrowerFirstName !== '' && employmentStatus !== EmploymentStatus.UNEMPLOYED,
      then: yup.number().min(0).max(99, 'Years at company must be less than or equal to 99 years').required(),
    }),

  coBorrowerZipCode: yup.string()
    .when(['$hasCoBorrower', '$hasDifferentCoBorrowerAddress'], {
      is: (hasCoBorrower: boolean, hasDifferentCoBorrowerAddress: boolean) => (hasCoBorrower && hasDifferentCoBorrowerAddress),
      then: yup.string().matches(/^\d{5}$/g, 'requires valid zip code').label('Zip Code').required(),
    }),

  discountPoints: yup.string().required(),

  loanTerm: yup.string().oneOf(['fifteen', 'twenty', 'thirty']).required(),

  propertyCashOut: yup.number().min(0).typeError('Cash out value is required')
    .when('applicationType', (applicationType: string, schema) => (applicationType === 'refinance' || applicationType === 'heloc' ? schema.required() : schema)),

  propertyCity: yup.string()
    .when(['applicationType', 'borrowerContractStatus'], {
      is: (applicationType: string, borrowerContractStatus: string) => (applicationType === 'refinance' || applicationType === 'heloc' || borrowerContractStatus === CONTRACT_STATUSES.inContract),
      then: yup.string().label('City').required(),
    }),

  propertyCounty: yup.string().label('County').required(),

  propertyDownPayment: yup.number().min(1)
    .when('applicationType', (applicationType: string, schema) => (applicationType === 'purchase' ? schema.required() : schema)),

  propertyDownPaymentPartiallyGift: yup.string().oneOf(['Yes', 'No'])
    .when('applicationType', (applicationType: string, schema) => (applicationType === 'purchase' ? schema.required() : schema)),

  propertyHomeValue: yup.number().min(1).typeError('Home Value is required')
    .when('applicationType', (applicationType: string, schema) => (applicationType === 'refinance' || applicationType === 'heloc' ? schema.required() : schema)),

  propertyMortgageBalance: yup.number().min(1).typeError('Outstanding Mortgage Balance is required')
    .when('applicationType', (applicationType: string, schema) => (applicationType === 'refinance' || applicationType === 'heloc' ? schema.required() : schema)),

  propertyPurchasePrice: yup.number().min(1)
    .when('applicationType', (applicationType: string, schema) => (applicationType === 'purchase' ? schema.required() : schema)),

  propertyResidenceType: yup.string().oneOf(['Primary Residence', 'Secondary Residence', 'Investment'], 'Residence Type is required').required().label('Residence Type'),

  propertyState: yup
    .string()
    .oneOf(STATE_OPTIONS.map((kvp) => kvp.value), ({ value }: { value: any }) => {
      /**
       * notOneOf removes values from the array passed to oneOf, so we have to do our error message checks in here.
       */
      if (UNSUPPORTED_STATES.has(value)) {
        return 'It looks like Lower is not available in your state, check back soon as we are expanding rapidly.';
      }
      return 'State is required';
    })
    .notOneOf([...UNSUPPORTED_STATES], 'It looks like Lower is not available in your state, check back soon as we are expanding rapidly.')
    .required('State is required')
    .label('State'),

  propertyStreetAddress: yup.string()
    .when(['applicationType', 'borrowerContractStatus'], {
      is: (applicationType: string, borrowerContractStatus: string) => (applicationType === 'refinance' || applicationType === 'heloc' || borrowerContractStatus === CONTRACT_STATUSES.inContract),
      then: yup.string().label('Street Address').required(),
    }),

  propertyType: yup.string().oneOf(['Single Family', 'Condo', 'Duplex', 'Triplex', 'Fourplex'], 'Property Type is required').required(),

  propertyZipCode: yup.string().matches(/^\d{5}$/g, 'requires valid zip code').label('Zip Code').required(),
}, [['borrowerYearlySalary', 'borrowerOtherIncomePerYear']]);
