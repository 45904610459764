import { MortgageApplicationStore } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { BestAvailablePayload, Loan, Property, Borrower, CoBorrower } from '@lightspeed/api/services/personalizedLoanProducts/bestavailable';
import { getLocalUuid } from '@lightspeed/utils/get-local-uuid';
import { STATE_NAME_TO_ABBREVIATION } from '@lightspeed/models/us-states';
import { transformDateToISO } from '@lightspeed/utils/transform-date-to-iso';

function transformLoan(application: MortgageApplicationStore, type: 'Purchase'|'Refinance'|'Heloc'): Loan {
  if (type === 'Purchase') {
    return {
      amount: parseInt(application.propertyPurchasePrice, 10) - parseInt(application.propertyDownPayment, 10),
      purpose: 'Purchase',
    };
  }
  return {
    amount: parseInt(application.propertyMortgageBalance, 10),
    cashOutAmount: parseInt(application.propertyCashOut, 10),
    purpose: 'Refinance',
  };
}

const EMPLOYMENT_STATUS_MAP: Record<string, 'W2'|'SelfEmployed'|'Retired'> = {
  'Retired': 'Retired',
  'Self Employed': 'SelfEmployed',
  'W-2': 'W2',
};

function transformBorrower(application: MortgageApplicationStore): Borrower {
  return {
    creditAuthorization: {
      authorizedAt: application.borrowerCreditAuthDateTime,
      referenceId: getLocalUuid('borrowerUUID'),
    },
    financialInformation: {
      otherIncomePerYear: parseInt(application.borrowerOtherIncomePerYear, 10),
      yearlySalary: parseInt(application.borrowerYearlySalary, 10),
    },
    firstTimeHomeBuyer: application.borrowerFirstTimeHomeBuyer === 'Yes',
    personalInformation: {
      address: {
        city: application.borrowerCity,
        county: application.borrowerCounty,
        state: STATE_NAME_TO_ABBREVIATION.get(application.borrowerState) as string,
        street: application.borrowerStreetAddress,
        zipCode: application.borrowerZipCode,
      },
      dateOfBirth: application.borrowerDateOfBirth && transformDateToISO(application.borrowerDateOfBirth),
      email: application.borrowerEmail,
      employmentStatus: EMPLOYMENT_STATUS_MAP[application.borrowerEmploymentStatus],
      firstName: application.borrowerFirstName,
      lastName: application.borrowerLastName,
      phoneNumber: application.borrowerPhoneNumber,
      ssn: application.borrowerSocialSecurityNumber,
    },
  };
}

function transformCoBorrower(application: MortgageApplicationStore): CoBorrower {
  return {
    creditAuthorization: {
      authorizedAt: application.coBorrowerCreditAuthDateTime,
      referenceId: getLocalUuid('coborrowerUUID'),
    },
    financialInformation: {
      otherIncomePerYear: parseInt(application.coBorrowerOtherIncomePerYear, 10),
      yearlySalary: parseInt(application.coBorrowerYearlySalary, 10),
    },
    personalInformation: {
      address: {
        city: application.coBorrowerCity,
        county: application.coBorrowerCounty,
        state: STATE_NAME_TO_ABBREVIATION.get(application.coBorrowerState) as string,
        street: application.coBorrowerStreetAddress,
        zipCode: application.coBorrowerZipCode,
      },
      dateOfBirth: application.coBorrowerDateOfBirth && transformDateToISO(application.coBorrowerDateOfBirth),
      email: application.coBorrowerEmail,
      employmentStatus: EMPLOYMENT_STATUS_MAP[application.coBorrowerEmploymentStatus],
      firstName: application.coBorrowerFirstName,
      lastName: application.coBorrowerLastName,
      phoneNumber: application.coBorrowerPhoneNumber,
      ssn: application.coBorrowerSocialSecurityNumber,
    },
  };
}

const PROPERTY_TYPE_MAPPING: Record<string, 'SingleFamily'|'Condo'|'Duplex'|'Triplex'|'Fourplex'> = {
  'Condo': 'Condo',
  'Duplex': 'Duplex',
  'Fourplex': 'Fourplex',
  'Single Family': 'SingleFamily',
  'Triplex': 'Triplex',
};

const PROPERTY_OCCUPANCY_MAPPING: Record<string, 'PrimaryResidence'|'SecondHome'|'Investment'> = {
  'Investment': 'Investment',
  'Primary Residence': 'PrimaryResidence',
  'Secondary Residence': 'SecondHome',
};

function transformProperty(application: MortgageApplicationStore, type: 'Purchase'|'Refinance'|'Heloc'): Property {
  return {
    address: {
      city: application.propertyCity || null,
      county: application.propertyCounty,
      state: STATE_NAME_TO_ABBREVIATION.get(application.propertyState) as string,
      street: application.propertyStreetAddress || null,
      zipCode: application.propertyZipCode,
    },
    occupancy: PROPERTY_OCCUPANCY_MAPPING[application.propertyResidenceType],
    propertyType: PROPERTY_TYPE_MAPPING[application.propertyType],
    value: parseInt(type === 'Purchase' ? application.propertyPurchasePrice : application.propertyHomeValue, 10),
  };
}

export function transformApplicationToLoanProductsPayload(application: MortgageApplicationStore, type: 'Purchase'|'Refinance'|'Heloc'): BestAvailablePayload {
  const payload: BestAvailablePayload = {
    borrower: transformBorrower(application),
    loan: transformLoan(application, type),
    property: transformProperty(application, type),
  };

  if (application.coBorrowerFirstName) {
    payload.coBorrower = transformCoBorrower(application);
  }

  return payload;
};
