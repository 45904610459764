import { IndividualPersonalizedLoan } from '@lightspeed/api/services/personalizedLoanProducts/bestavailable';

export function getLowestRateLoanProduct(
  loanProducts: IndividualPersonalizedLoan[],
): IndividualPersonalizedLoan {
  return loanProducts.reduce(
    (lowestRateLPFound: IndividualPersonalizedLoan, currentLP: IndividualPersonalizedLoan) =>
      (currentLP.rate < lowestRateLPFound.rate ? currentLP : lowestRateLPFound), loanProducts[0],
  );
}
