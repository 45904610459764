import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MortgageApplicationStore,
} from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import {
  useMortgageApplication,
} from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { ROUTES } from '@lightspeed/routing/routing-machine';
import { IndividualPersonalizedLoan } from '@lightspeed/api/services/personalizedLoanProducts/bestavailable';
import { useApplicationLead } from '@lightspeed/hooks/useApplicationLead/useApplicationLead';
import { useProgress } from './use-progress';
import { ProcessingTemplate } from './processing-template';

const textPrompts = [
  'Plugging in your numbers...',
  'Uploading income...',
  'Checking assets...',
  'Confirming identity...',
  'Running soft credit pull...',
];

const completePartialLead = async (completeLead: (selectedLoan?: IndividualPersonalizedLoan | undefined) => Promise<Response>, application: MortgageApplicationStore) => {
  try {
    await completeLead();
  } catch (e) {
    // app insights tracking is done in completeLead so we don't need to do it here like we did on submit guided
    throw new Error('Complete Partial Lead Failed');
  }
}

const processSubmission = async (application: MortgageApplicationStore, navigate: (v: string) => void, completeLead: (selectedLoan?: IndividualPersonalizedLoan | undefined) => Promise<Response>) => {
  try {
    await completePartialLead(completeLead, application);
  } catch (e) {
    navigate(ROUTES.ERROR);
  }
};

export function ProcessingLeadSubmission() {
  const navigate = useNavigate();
  const { mortgageApplication } = useMortgageApplication();
  const goToNextRoute = useNextRoute(mortgageApplication);
  const [hasCompleted, setHasCompleted] = useState(false);
  const { completeLead } = useApplicationLead();

  useEffect(() => {
    if (hasCompleted) {
      goToNextRoute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCompleted]);

  const onSubmissionComplete = useCallback(() => {
    setHasCompleted(true)
  }, [setHasCompleted]);

  const [progress, prompt] = useProgress(
    textPrompts,
    () => processSubmission(mortgageApplication, navigate, completeLead),
    onSubmissionComplete,
  );

  return (
    <ProcessingTemplate
      progress={progress}
      prompt={prompt}
    />
  );
}
