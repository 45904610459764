import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Accordion } from '@lightspeed/components/ui/molecules';
import { responsiveStyleFromTheme, ThemedProps } from '@lightspeed/styles/utils/theme-utils';

export interface AccordionListProps {
  options: AccordionListOption[];
  defaultSelectedIndexes?: number[];
}

export interface AccordionListOption {
  title: string;
  values: Array<{
    label: string;
    value: string;
  }>
}

export const AccordionList = ({
  options,
  defaultSelectedIndexes = [],
  ...props
}: AccordionListProps) => {
  const [selectedIndexes, setSelectedIndex] = useState<Array<number>>(defaultSelectedIndexes);

  const toggleAccordion = (index: number) => {
    if (selectedIndexes.includes(index)) {
      setSelectedIndex(selectedIndexes.filter((item) => item !== index))
    } else {
      setSelectedIndex([...selectedIndexes, index])
    }
  }

  return (
    <Container {...props}>
      {
        options.map(
          (option, sectionIndex) => (
            <div key={`${option.title}-${sectionIndex}`}>
              <Accordion
                active={selectedIndexes.includes(sectionIndex)}
                onClick={() => toggleAccordion(sectionIndex)}
                label={option.title}
              >
                {
                  option.values.map(
                    ({ label, value }, listItemIndex) => (
                      <AccordionListItem key={`${ label }-${listItemIndex}`}>
                        <AccordionListLabel>{label}</AccordionListLabel>
                        <AccordionListValue>{value}</AccordionListValue>
                      </AccordionListItem>
                    ),
                  )
                }
              </Accordion>
              {sectionIndex !== options.length - 1 && <AccordionListSeparator data-testid={'divider'} />}
            </div>
          ),
        )
      }
    </Container>
  )
};

const Container = styled.div`
  width: 100%;
`;

const AccordionListLabel = styled.div((props: ThemedProps) => css`
  ${props.theme.Body.regular};
  flex: 0 0 auto;
  color: #6A6B76;
  max-width: 70%;
`);

const AccordionListValue = styled.div((props: ThemedProps) => css`
  ${responsiveStyleFromTheme(props.theme.Headers.h5)};
  flex: 1;
  display: flex;
  justify-content: right;
`);

const AccordionListItem = styled.div`
  display: flex;
  margin: 10px 0 20px 0;
`;

const AccordionListSeparator = styled.div`
  height: 1px;
  width: 100%;
  margin: 10px 0;
  background: linear-gradient(to left, rgba(28, 28, 30, 0),rgba(28, 28, 30, 0.1),rgba(28, 28, 30, 0));
`;
