import React, { useEffect, useState } from 'react';
import { TodaysRates } from '@lightspeed/components/ui/pages/v2/get-started/basic-info/todays-rates';
import { CardSelect } from '@lightspeed/components/ui/organisms';
import { SlideToggle } from '@lightspeed/components/ui/atoms/slide-toggle/slide-toggle';
import { Heading, CurrencyInput, Button, Label, OptionalElement, TextInput, Symbol } from '@lightspeed/components/ui/atoms';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { ValidationError } from '@lightspeed/components/ui/molecules/validation-error/validation-error';
import { Page } from '@lightspeed/components/ui/templates/base-page-template/page';
import { SplitSection } from '@lightspeed/components/ui/templates/split-section';
import { TermSelect } from '@lightspeed/components/shared/terms-select/terms-select';
import { Form, FormRow } from '@lightspeed/components/ui/templates/form/form';
import { Select } from '@lightspeed/components/ui/atoms/select';
import { BasePageTemplate } from '@lightspeed/components/ui/templates';
import { MortgageApplicationStore, MortgageApplicationStoreKeys } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { useValidation } from '@lightspeed/hooks/useValidation/useValidation';
import { getButtonDisabledState } from '@lightspeed/utils/get-button-disabled-state';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { STATE_OPTIONS } from '@lightspeed/models/us-states';
import { ToolTip, LoadingModal } from '@lightspeed/components/ui/molecules';
import { RateDisclaimer } from '@lightspeed/components/shared/legal-disclaimers/rate-disclaimer';
import { PurchaseRefinanceSidebarBlurb } from '@lightspeed/components/shared/purchase-refinance-sidebar-blurb/purchase-refinance-sidebar-blurb';
import { useFireBadStateGAEvent } from '@lightspeed/hooks/useFireBadStateGAEvent/useFireBadStateGAEvent';
import { useMortgageContextDefault } from '@lightspeed/hooks/useMortgageContextDefaults/useMortgageContextDefaults';
import { useApplicationLead } from '@lightspeed/hooks/useApplicationLead/useApplicationLead';
import { getDownPaymentOptions } from '@lightspeed/components/ui/pages/v2/property-purchase/get-down-payment-options';
import { useAnalytics } from '@lightspeed/hooks/useAnalytics/useAnalytics';
import {
  mortgageApplicationSchema,
} from '@lightspeed/contexts/mortgage-application-context/mortage-application-validation';

const validationKeys: MortgageApplicationStoreKeys[] = [
  'propertyPurchasePrice',
  'propertyType',
  'propertyDownPayment',
  'propertyDownPaymentPartiallyGift',
  'propertyResidenceType',
  'propertyType',
  'propertyState',
  'propertyCity',
];

interface PropertyFormProps {
  mortgageApplication: MortgageApplicationStore;
  updateMortgageApplication: (v: keyof MortgageApplicationStore, z: string) => void;
  errors: Map<string, string[]>|null;
  isFirstTimeHomeBuyer: boolean;
  setDownPaymentDecimalString: (v: string) => void;
  downPaymentDecimalString: string;
}

function PropertyForm({
  mortgageApplication,
  errors,
  updateMortgageApplication,
  isFirstTimeHomeBuyer,
  setDownPaymentDecimalString,
  downPaymentDecimalString,
}: PropertyFormProps) {
  return (
    <Form>
      <Heading
        element={'h4'}
      >
        Property Details
      </Heading>
      <FormRow>
        <Label flex={1}>
          <span>
            Purchase Price
            <ToolTip bubble={'The purchase price is the amount you agree to pay the seller.'}>
              <Symbol type={'Info'} />
            </ToolTip>
          </span>
          <CurrencyInput
            value={mortgageApplication.propertyPurchasePrice}
            onChange={(v: string | undefined) => {
              updateMortgageApplication('propertyPurchasePrice', v || '');
            }}
            placeholder={'$300,000'}
            allowDecimals
          />
          <ValidationError
            path={'propertyPurchasePrice'}
            errors={errors}
          />
        </Label>
        <Label flex={['0 0 100%', 1]}>
          <span>
            Property Type
            <ToolTip bubble={'Getting to know your property allows us to customize your loan and rate for you.'}>
              <Symbol type={'Info'} />
            </ToolTip>
          </span>
          <Select
            name={'property-type'}
            options={[
              {
                label: 'Select One',
                value: '',
              },
              {
                label: 'Single Family',
                value: 'Single Family',
              }, {
                label: 'Condo',
                value: 'Condo',
              }, {
                label: 'Duplex',
                value: 'Duplex',
              }, {
                label: 'Triplex',
                value: 'Triplex',
              }, {
                label: 'Fourplex',
                value: 'Fourplex',
              },
            ]}
            value={mortgageApplication.propertyType}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateMortgageApplication('propertyType', e.target.value)}
          />
          <ValidationError
            path={'propertyType'}
            errors={errors}
          />
        </Label>
      </FormRow>

      <FormRow>
        <Label flex={['0 0 100%', 1]}>
          Down Payment
        </Label>
        <CardSelect
          onChange={(v) => {
            setDownPaymentDecimalString(v);
          }}
          value={downPaymentDecimalString}
          options={getDownPaymentOptions(isFirstTimeHomeBuyer)}
        />
        <ValidationError
          path={'propertyDownPayment'}
          errors={errors}
        />
      </FormRow>

      <FormRow>
        <Label
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            gap: '1em',
          }}
        >
          My down payment is partially a gift
          <SlideToggle
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateMortgageApplication('propertyDownPaymentPartiallyGift', e.target.checked ? 'Yes' : 'No');
            }}
            checked={mortgageApplication.propertyDownPaymentPartiallyGift === 'Yes'}
          />
        </Label>
      </FormRow>

      <FormRow>
        <Label flex={['0 0 100%', 1]}>
          <span>
            Property Use*
            <ToolTip bubble={'How you use the property affects the home loan. Remember, you can only have one primary residence. The rest can be secondary (vacation) homes or investment properties.'}>
              <Symbol type={'Info'} />
            </ToolTip>
          </span>
          <Select
            name={'property-use'}
            options={[
              {
                label: 'Select One',
                value: '',
              }, {
                label: 'Primary Residence',
                value: 'Primary Residence',
              }, {
                label: 'Secondary Residence',
                value: 'Secondary Residence',
              }, {
                label: 'Investment',
                value: 'Investment',
              }]}
            value={mortgageApplication.propertyResidenceType}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateMortgageApplication('propertyResidenceType', e.target.value)}
          />
          <ValidationError
            path={'propertyResidenceType'}
            errors={errors}
          />
        </Label>
        <Label flex={['0 0 100%', 1]}>
          City*
          <TextInput
            name={'propertyCity'}
            value={mortgageApplication.propertyCity}
            onChange={(e) => { updateMortgageApplication('propertyCity', e.target.value); }}
            placeholder={'e.g. Columbus'}
          />
          <ValidationError
            path={'propertyCity'}
            errors={errors}
          />
        </Label>
      </FormRow>
      <FormRow>
        <Label flex={['0 0 100%', '0 0 calc(50% - 9px)']}>
          State*
          <Select
            name={'state-select'}
            placeholder={'Select One'}
            options={[{
              label: 'Select One',
              value: '',
            }, ...STATE_OPTIONS]}
            value={mortgageApplication.propertyState}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateMortgageApplication('propertyState', e.target.value)}
          />
          <ValidationError
            path={'propertyState'}
            errors={errors}
          />
        </Label>
      </FormRow>
    </Form>
  );
}

export function PropertyPurchaseNotInContract() {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const isFirstTimeHomeBuyer = mortgageApplication.borrowerFirstTimeHomeBuyer === 'Yes';
  const goToNextRoute = useNextRoute(mortgageApplication);
  const fireAnalyticsEvent = useAnalytics('v2/property_purchase/not_in_contract');
  const [downPaymentDecimalString, setDownPaymentDecimalString] = useState(isFirstTimeHomeBuyer ? '.03' : '.05');
  const { upsertLead } = useApplicationLead();
  const [showLoadingModal, setShowLoadingModal] = useState(false);

  useMortgageContextDefault('propertyDownPaymentPartiallyGift');

  const [validate, errors] = useValidation(mortgageApplicationSchema, {
    keysToValidate: validationKeys,
  });

  useFireBadStateGAEvent(mortgageApplication, errors, fireAnalyticsEvent);

  const disableNextButton = getButtonDisabledState<MortgageApplicationStore>(
    mortgageApplication,
    validationKeys,
  );

  useEffect(() => {
    const calculatedDownPayment = parseFloat(downPaymentDecimalString) * parseFloat(mortgageApplication.propertyPurchasePrice);
    updateMortgageApplication('propertyDownPayment', calculatedDownPayment.toString());
  }, [downPaymentDecimalString, mortgageApplication.propertyPurchasePrice]); // eslint-disable-line react-hooks/exhaustive-deps

  const onNextClick = () => {
    (async () => {
      const validationResult = validate(mortgageApplication);

      if (validationResult.success) {
        setShowLoadingModal(true);
        try {
          await upsertLead();
          setShowLoadingModal(false);
        } catch (e) {
          // do nothing
        }

        fireAnalyticsEvent('next_button_success');
        goToNextRoute();
      }
    })();
  };

  return (
    <>
      <OptionalElement show={showLoadingModal}>
        <LoadingModal />
      </OptionalElement>
      <Page
        desktop={(
          <>
            <Header />
            <BasePageTemplate>
              <SplitSection>
                <SplitSection.Content>
                  <Heading element={'h1'}>
                    The Property.
                  </Heading>
                  <PropertyForm
                    mortgageApplication={mortgageApplication}
                    updateMortgageApplication={updateMortgageApplication}
                    downPaymentDecimalString={downPaymentDecimalString}
                    setDownPaymentDecimalString={setDownPaymentDecimalString}
                    isFirstTimeHomeBuyer={isFirstTimeHomeBuyer}
                    errors={errors}
                  />
                </SplitSection.Content>

                <SplitSection.Sidebar>
                  <OptionalElement show={mortgageApplication.applicationType !== 'Heloc'}>
                    <TodaysRates
                      mortgageApplication={mortgageApplication}
                    />
                  </OptionalElement>
                  <Label marginBottom={'8px'}>
                    Loan Term
                  </Label>
                  <TermSelect
                    value={mortgageApplication.loanTerm}
                    onChange={(v) => {
                      updateMortgageApplication('loanTerm', v);
                    }}
                  />
                  <OptionalElement show={mortgageApplication.applicationType === 'Purchase'}>
                    <PurchaseRefinanceSidebarBlurb />
                  </OptionalElement>
                  <Button
                    marginY={'32px'}
                    disabled={disableNextButton}
                    onClick={onNextClick}
                  >
                    Next
                  </Button>
                  <RateDisclaimer />
                </SplitSection.Sidebar>
              </SplitSection>
            </BasePageTemplate>
          </>
        )}
        mobile={(
          <>
            <Header />
            <BasePageTemplate>
              <Heading element={'h1'}>
                The Property.
              </Heading>
              <PropertyForm
                mortgageApplication={mortgageApplication}
                updateMortgageApplication={updateMortgageApplication}
                downPaymentDecimalString={downPaymentDecimalString}
                setDownPaymentDecimalString={setDownPaymentDecimalString}
                isFirstTimeHomeBuyer={isFirstTimeHomeBuyer}
                errors={errors}
              />
              <Button
                marginTop={'32px'}
                marginBottom={'32px'}
                disabled={disableNextButton}
                onClick={onNextClick}
              >
                Next
              </Button>
            </BasePageTemplate>
          </>
        )}
      />
    </>
  );
}
