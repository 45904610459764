import cookies, { CookieAttributes } from 'js-cookie';
import { useEffect, useState } from 'react';

const utmSourceKey = 'utm_source';
const utmCampaignKey = 'utm_campaign';
const utmMediumKey = 'utm_medium';
const utmTermKey = 'utm_term';
const utmContentKey = 'utm_content';

function tokenOptions(): CookieAttributes {
  const hostnameParts = window.location.hostname.split('.');

  return {
    domain: `.${hostnameParts[hostnameParts.length - 2]}.${hostnameParts[hostnameParts.length - 1]}`,
  };
}

export function removeUtmSource(): void {
  cookies.remove(utmSourceKey, tokenOptions());
}

export function getUtmSource(): string | null {
  return cookies.get(utmSourceKey) || null;
}

export function setUtmSource(token: string): void {
  cookies.set(utmSourceKey, token, tokenOptions());
}

export function removeUtmCampaign(): void {
  cookies.remove(utmCampaignKey, tokenOptions());
}

export function getUtmCampaign(): string | null {
  return cookies.get(utmCampaignKey) || null;
}

export function setUtmCampaign(token: string): void {
  cookies.set(utmCampaignKey, token, tokenOptions());
}

export function removeUtmMedium(): void {
  cookies.remove(utmMediumKey, tokenOptions());
}

export function getUtmMedium(): string | null {
  return cookies.get(utmMediumKey) || null;
}

export function setUtmMedium(token: string): void {
  cookies.set(utmMediumKey, token, tokenOptions());
}

export function removeUtmContent(): void {
  cookies.remove(utmContentKey, tokenOptions());
}

export function getUtmContent(): string | null {
  return cookies.get(utmContentKey) || null;
}

export function setUtmContent(token: string): void {
  cookies.set(utmContentKey, token, tokenOptions());
}

export function removeUtmTerm(): void {
  cookies.remove(utmTermKey, tokenOptions());
}

export function getUtmTerm(): string | null {
  return cookies.get(utmTermKey) || null;
}

export function setUtmTerm(token: string): void {
  cookies.set(utmTermKey, token, tokenOptions());
}

function getInitialAttributionValues(): UtmAttributionValues {
  const url = new URL(window.location.href);

  const queryUtmMedium = url.searchParams.get(utmMediumKey);
  const queryUtmSource = url.searchParams.get(utmSourceKey);
  const queryUtmCampaign = url.searchParams.get(utmCampaignKey);
  const queryUtmContent = url.searchParams.get(utmContentKey);
  const queryUtmTerm = url.searchParams.get(utmTermKey);

  return {
    utmMedium: queryUtmMedium || getUtmMedium(),
    utmSource: queryUtmSource || getUtmSource(),
    utmCampaign: queryUtmCampaign || getUtmCampaign(),
    utmContent: queryUtmContent || getUtmContent(),
    utmTerm: queryUtmTerm || getUtmTerm(),
  }
}

interface UtmAttributionValues {
  utmSource: string | null;
  utmMedium: string | null;
  utmCampaign: string | null;
  utmContent: string | null;
  utmTerm: string | null;
}

export function useUtmParams() {
  const [attributionValues] = useState<UtmAttributionValues>(() => getInitialAttributionValues());

  useEffect(() => {
    if (attributionValues.utmCampaign) {
      setUtmCampaign(attributionValues.utmCampaign);
    }

    if (attributionValues.utmMedium) {
      setUtmMedium(attributionValues.utmMedium);
    }

    if (attributionValues.utmSource) {
      setUtmSource(attributionValues.utmSource);
    }

    if (attributionValues.utmContent) {
      setUtmContent(attributionValues.utmContent);
    }  
    
    if (attributionValues.utmTerm) {
      setUtmTerm(attributionValues.utmTerm);
    }
  }, [])

  return attributionValues;
}
