import { useState, useEffect, useRef } from 'react';

export function useProgress(
  textPrompts: string[],
  asyncAction: () => Promise<void>,
  onComplete: () => void,
  estimatedSecondsToComplete = 4,
): [number, string] {
  const hasResolvedRef = useRef<boolean>(false);
  const hasErroredRef = useRef<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        await asyncAction();
        hasResolvedRef.current = true;
      } catch (e) {
        hasErroredRef.current = true;
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [progress, setProgress] = useState<number>(0);

  const divisor = 100 / textPrompts.length;
  const promptIndex = Math.floor(progress / divisor);
  const prompt = textPrompts[promptIndex] || textPrompts[textPrompts.length - 1];

  useEffect(() => {
    (async () => {
      let i = 1;

      // eslint-disable-next-line no-constant-condition
      while (true) {
        // we want to return from this async function if we have errored or resolved
        // we only call onComplete if there is not an error
        if (i >= 100 && (hasResolvedRef.current || hasErroredRef.current)) {
          if (!hasErroredRef.current) {
            onComplete();
          }
          return;
        }
        // eslint-disable-next-line no-await-in-loop
        await new Promise((res) => { setTimeout(res, estimatedSecondsToComplete * 10) });
        setProgress(Math.min(i, 100));

        // slow down the timer if it's taking longer than we estimated
        if (i > 90 && !hasResolvedRef.current) {
          i += .25;
        } else if (i > 75 && !hasResolvedRef.current) {
          i += .5;
        } else if (hasResolvedRef.current) {
          i += 4;
        } else {
          i++;
        }
      }
    })();
  }, [estimatedSecondsToComplete, onComplete]);

  return [progress, prompt];
}
