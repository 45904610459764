import styled from 'styled-components';
import { responsiveStyleFromTheme } from '@lightspeed/styles/utils/theme-utils';

export const BasePageTemplate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 24px;
  max-width: 1200px;
  margin: 0 auto 60px auto;
  flex-grow: 1;

  ${responsiveStyleFromTheme({
  desktop: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '0 60px',
  },
})}
`;
