export const STATE_OPTIONS = [
  {
    label: 'Alaska',
    value: 'Alaska',
  },
  {
    label: 'Alabama',
    value: 'Alabama',
  },
  {
    label: 'Arkansas',
    value: 'Arkansas',
  },
  {
    label: 'Arizona',
    value: 'Arizona',
  },
  {
    label: 'California',
    value: 'California',
  },
  {
    label: 'Colorado',
    value: 'Colorado',
  },
  {
    label: 'Connecticut',
    value: 'Connecticut',
  },
  {
    label: 'District Of Columbia',
    value: 'District Of Columbia',
  },
  {
    label: 'Delaware',
    value: 'Delaware',
  },
  {
    label: 'Florida',
    value: 'Florida',
  },
  {
    label: 'Georgia',
    value: 'Georgia',
  },
  {
    label: 'Hawaii',
    value: 'Hawaii',
  },
  {
    label: 'Iowa',
    value: 'Iowa',
  },
  {
    label: 'Idaho',
    value: 'Idaho',
  },
  {
    label: 'Illinois',
    value: 'Illinois',
  },
  {
    label: 'Indiana',
    value: 'Indiana',
  },
  {
    label: 'Kansas',
    value: 'Kansas',
  },
  {
    label: 'Kentucky',
    value: 'Kentucky',
  },
  {
    label: 'Louisiana',
    value: 'Louisiana',
  },
  {
    label: 'Massachusetts',
    value: 'Massachusetts',
  },
  {
    label: 'Maryland',
    value: 'Maryland',
  },
  {
    label: 'Maine',
    value: 'Maine',
  },
  {
    label: 'Michigan',
    value: 'Michigan',
  },
  {
    label: 'Minnesota',
    value: 'Minnesota',
  },
  {
    label: 'Missouri',
    value: 'Missouri',
  },
  {
    label: 'Mississippi',
    value: 'Mississippi',
  },
  {
    label: 'Montana',
    value: 'Montana',
  },
  {
    label: 'North Carolina',
    value: 'North Carolina',
  },
  {
    label: 'North Dakota',
    value: 'North Dakota',
  },
  {
    label: 'Nebraska',
    value: 'Nebraska',
  },
  {
    label: 'New Hampshire',
    value: 'New Hampshire',
  },
  {
    label: 'New Jersey',
    value: 'New Jersey',
  },
  {
    label: 'New Mexico',
    value: 'New Mexico',
  },
  {
    label: 'Nevada',
    value: 'Nevada',
  },
  {
    label: 'New York',
    value: 'New York',

  },
  {
    label: 'Ohio',
    value: 'Ohio',
  },
  {
    label: 'Oklahoma',
    value: 'Oklahoma',
  },
  {
    label: 'Oregon',
    value: 'Oregon',
  },
  {
    label: 'Pennsylvania',
    value: 'Pennsylvania',
  },
  {
    label: 'Rhode Island',
    value: 'Rhode Island',

  },
  {
    label: 'South Carolina',
    value: 'South Carolina',
  },
  {
    label: 'South Dakota',
    value: 'South Dakota',
  },
  {
    label: 'Tennessee',
    value: 'Tennessee',
  },
  {
    label: 'Texas',
    value: 'Texas',
  },
  {
    label: 'Utah',
    value: 'Utah',
  },
  {
    label: 'Virginia',
    value: 'Virginia',
  },
  {
    label: 'Vermont',
    value: 'Vermont',
  },
  {
    label: 'Washington',
    value: 'Washington',
  },
  {
    label: 'Wisconsin',
    value: 'Wisconsin',
  },
  {
    label: 'West Virginia',
    value: 'West Virginia',
  },
  {
    label: 'Wyoming',
    value: 'Wyoming',
  },
];

export const STATE_ABBREVIATION_TO_NAME = new Map<string, string>([
  ['AK', 'Alaska'],
  ['AL', 'Alabama'],
  ['AR', 'Arkansas'],
  ['AZ', 'Arizona'],
  ['CA', 'California'],
  ['CO', 'Colorado'],
  ['CT', 'Connecticut'],
  ['DC', 'District Of Columbia'],
  ['DE', 'Delaware'],
  ['FL', 'Florida'],
  ['GA', 'Georgia'],
  ['HI', 'Hawaii'],
  ['IA', 'Iowa'],
  ['ID', 'Idaho'],
  ['IL', 'Illinois'],
  ['IN', 'Indiana'],
  ['KS', 'Kansas'],
  ['KY', 'Kentucky'],
  ['LA', 'Louisiana'],
  ['MA', 'Massachusetts'],
  ['MD', 'Maryland'],
  ['ME', 'Maine'],
  ['MI', 'Michigan'],
  ['MN', 'Minnesota'],
  ['MO', 'Missouri'],
  ['MS', 'Mississippi'],
  ['MT', 'Montana'],
  ['NC', 'North Carolina'],
  ['ND', 'North Dakota'],
  ['NE', 'Nebraska'],
  ['NH', 'New Hampshire'],
  ['NJ', 'New Jersey'],
  ['NM', 'New Mexico'],
  ['NV', 'Nevada'],
  ['NY', 'New York'],
  ['OH', 'Ohio'],
  ['OK', 'Oklahoma'],
  ['OR', 'Oregon'],
  ['PA', 'Pennsylvania'],
  ['RI', 'Rhode Island'],
  ['SC', 'South Carolina'],
  ['SD', 'South Dakota'],
  ['TN', 'Tennessee'],
  ['TX', 'Texas'],
  ['UT', 'Utah'],
  ['VA', 'Virginia'],
  ['VT', 'Vermont'],
  ['WA', 'Washington'],
  ['WI', 'Wisconsin'],
  ['WV', 'West Virginia'],
  ['WY', 'Wyoming'],
]);

export const STATE_NAME_TO_ABBREVIATION = new Map<string, string>([
  ['Alaska', 'AK'],
  ['Alabama', 'AL'],
  ['Arkansas', 'AR'],
  ['Arizona', 'AZ'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['District Of Columbia', 'DC'],
  ['Delaware', 'DE'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Hawaii', 'HI'],
  ['Iowa', 'IA'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Massachusetts', 'MA'],
  ['Maryland', 'MD'],
  ['Maine', 'ME'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Missouri', 'MO'],
  ['Mississippi', 'MS'],
  ['Montana', 'MT'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Nebraska', 'NE'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['Nevada', 'NV'],
  ['New York', 'NY'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Virginia', 'VA'],
  ['Vermont', 'VT'],
  ['Washington', 'WA'],
  ['Wisconsin', 'WI'],
  ['West Virginia', 'WV'],
  ['Wyoming', 'WY'],
]);

export const UNSUPPORTED_STATES = new Set<string>([
  'Alaska',
  'New York',
  'Hawaii',
  'South Dakota',
  'Nevada',
  'Rhode Island',
  'Vermont',
  'Wyoming',
]);
