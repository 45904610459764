import cookies, { CookieAttributes } from 'js-cookie';
import { useEffect, useState } from 'react';

const key = 'lo';

function tokenOptions(): CookieAttributes {
  const hostnameParts = window.location.hostname.split('.');

  return {
    domain: `.${hostnameParts[hostnameParts.length - 2]}.${hostnameParts[hostnameParts.length - 1]}`,
  };
}

export function removeLoanOfficerToken(): void {
  cookies.remove(key, tokenOptions());
}

export function getLoanOfficerToken(): string | null {
  return cookies.get(key) || null;
}

export function setLoanOfficerToken(token: string): void {
  cookies.set(key, token, tokenOptions());
}

function getInitialToken(): string | null {
  const url = new URL(window.location.href);

  const queryToken = url.searchParams.get(key);

  return queryToken || getLoanOfficerToken();
}

export function useLoanOfficerToken() {
  const [token] = useState<string | null>(() => getInitialToken());

  useEffect(() => {
    const url = new URL(window.location.href);

    const queryToken = url.searchParams.get(key);

    if (queryToken) {
      setLoanOfficerToken(queryToken);
    }
  }, [])

  return token;
}
