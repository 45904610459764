import React, { useState } from 'react';
import styled from 'styled-components';
import { BasePageTemplate   } from '@lightspeed/components/ui/templates';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { Heading, Button, Paragraph, IconCircle, OptionalElement } from '@lightspeed/components/ui/atoms';
import { Page } from '@lightspeed/components/ui/templates/base-page-template/page';
import { SplitSection } from '@lightspeed/components/ui/templates/split-section';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { useAnalytics } from '@lightspeed/hooks/useAnalytics/useAnalytics';
import { MortgageApplicationStoreKeys, MortgageApplicationStore } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { getButtonDisabledState } from '@lightspeed/utils/get-button-disabled-state';
import { useValidation } from '@lightspeed/hooks/useValidation/useValidation';
import { mortgageApplicationSchema } from '@lightspeed/contexts/mortgage-application-context/mortage-application-validation';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { Explainer } from '@lightspeed/components/ui/molecules/explainer/explainer';
import { responsiveStyleFromTheme } from '@lightspeed/styles/utils/theme-utils';
import Shield from '@lightspeed/assets/icons/shield.svg';
import { useApplicationLead } from '@lightspeed/hooks/useApplicationLead/useApplicationLead';
import { LoadingModal } from '@lightspeed/components/ui/molecules';
import { IdentityForm } from './identity-form';

const borrowerKeys: MortgageApplicationStoreKeys[] = [
  'borrowerCreditAuthDateTime',
  'borrowerSocialSecurityNumber',
  'borrowerDateOfBirth',
];

const coBorrowerKeys: MortgageApplicationStoreKeys[] = [
  'coBorrowerCreditAuthDateTime',
  'coBorrowerSocialSecurityNumber',
  'coBorrowerDateOfBirth',
];

export function VerifyIdentity() {
  const { updateMortgageApplication, mortgageApplication } = useMortgageApplication();
  const goToNextRoute = useNextRoute(mortgageApplication);
  const fireAnalyticsEvent = useAnalytics('v2/identity');
  const hasCoBorrower = mortgageApplication.coBorrowerFirstName !== '';
  const { upsertLead } = useApplicationLead();
  const [showLoadingModal, setShowLoadingModal] = useState(false);

  const [validate, errors] = useValidation(mortgageApplicationSchema, {
    keysToValidate: hasCoBorrower ? [...borrowerKeys, ...coBorrowerKeys] : borrowerKeys,
    yupValidationContext: {
      hasCoBorrower,
    },
  });

  const disableNextButton = getButtonDisabledState<MortgageApplicationStore>(
    mortgageApplication,
    hasCoBorrower ? [...borrowerKeys, ...coBorrowerKeys] : borrowerKeys,
  );

  const onClick = async () => {
    if (validate(mortgageApplication).success) {
      setShowLoadingModal(true);
      try {
        await upsertLead();
        setShowLoadingModal(false);
      } catch (e) {} // eslint-disable-line no-empty

      fireAnalyticsEvent('next_button_success');
      goToNextRoute();
    }
  };

  return (
    <>
      <OptionalElement show={showLoadingModal}>
        <LoadingModal />
      </OptionalElement>

      <Page
        desktop={(
          <>
            <Header />
            <BasePageTemplate>
              <SplitSection>
                <SplitSection.Content>
                  <Heading
                    element={'h1'}
                  >
                    Let’s get started.
                  </Heading>
                  <IdentityForm
                    errors={errors}
                    mortgageApplication={mortgageApplication}
                    updateMortgageApplication={updateMortgageApplication}
                  />
                  <SplitSection.LegalFooter>
                    <Explainer
                      label={'Why do we ask for your SSN?'}
                    >
                      <Paragraph variant={'smallLight'}>
                        When doing anything related to borrowing a large sum of money (like quoting a rate) we need a bit of info about you to do the math. We’re looking at things like debt-to-income ratio and credit score when we run your soft credit check.
                      </Paragraph>
                    </Explainer>
                  </SplitSection.LegalFooter>
                </SplitSection.Content>
                <SplitSection.Sidebar>
                  <Blurb>
                    <BlurbIconContainer>
                      <IconCircle
                        background={'rgba(98, 54, 255, 0.1);;'}
                        icon={Shield}
                      />
                    </BlurbIconContainer>
                    <Heading
                      marginTop={'20px'}
                      element={'h5'}
                    >Your Data is Secure
                    </Heading>
                    <Paragraph variant={'smallLight'}>
                      We use industry-accepted best practices and frameworks. Your data is safe. We’ll never sell your info to anyone. Scout's honor.
                    </Paragraph>
                  </Blurb>
                  <Button
                    disabled={disableNextButton}
                    onClick={onClick}
                  >
                    Next
                  </Button>
                </SplitSection.Sidebar>
              </SplitSection>
            </BasePageTemplate>
          </>
        )}
        mobile={(
          <>
            <Header />
            <BasePageTemplate>
              <Heading
                element={'h1'}
              >
                Verify Identity
              </Heading>
              <IdentityForm
                errors={errors}
                mortgageApplication={mortgageApplication}
                updateMortgageApplication={updateMortgageApplication}
              />
              <Blurb>
                <BlurbIconContainer>
                  <IconCircle
                    background={'rgba(98, 54, 255, 0.1);;'}
                    icon={Shield}
                  />
                </BlurbIconContainer>
                <Heading element={'h5'}>Your Data is Secure</Heading>
                <Paragraph variant={'smallLight'}>
                  We use industry-accepted best practices and frameworks. Your data is safe. We’ll never sell your info to anyone. Scout's honor.
                </Paragraph>
              </Blurb>
              <Explainer
                label={'Why do we ask for your SSN?'}
              >
                <Paragraph variant={'smallLight'}>
                  When doing anything related to borrowing a large sum of money (like quoting a rate) we need a bit of info about you to do the math. We’re looking at things like debt-to-income ratio and credit score when we run your soft credit check.
                </Paragraph>
              </Explainer>
              <Button
                disabled={disableNextButton}
                onClick={onClick}
                type={'button'}
                marginY={'60px'}
              >
                Next
              </Button>
            </BasePageTemplate>
          </>
        )}
      />
    </>
  );
}

const BlurbIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Blurb = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  p {
    text-align: left;
  }

  ${responsiveStyleFromTheme({
  desktop: {
    alignItems: 'start',
    flexDirection: 'column',
    gap: '8px',
    marginBottom: '43px',
  },
  mobile: {
    alignItems: 'start',
    flexDirection: 'column',
    gap: '8px',
    marginBottom: '48px',
    marginTop: '89px',
  },
})}
`;
