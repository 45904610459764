import { Component } from 'react';
import { Error as ErrorPage } from '@lightspeed/components/ui/pages/error/error';
import { appInsights } from '@lightspeed/utils/app-insights';

interface ErrorBoundaryState { hasError: boolean }

export class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error) {
    appInsights.trackException({
      exception: error,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return <ErrorPage />;
    }

    return children;
  }
}
