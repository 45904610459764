import { useState } from 'react';

export default function useModal(initialState = false): [boolean, () => void, () => void,  () => void] {
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(initialState);

  const showModal = () => setShouldShowModal(true);

  const hideModal = () => setShouldShowModal(false);

  const toggleModal = () => setShouldShowModal((v) => !v);

  return [shouldShowModal, showModal, hideModal, toggleModal];
}
