import styled from 'styled-components';
import { renderToStaticMarkup } from 'react-dom/server';
import { ChangeEventHandler, FocusEventHandler } from 'react';
import cx from 'classnames';
import { ChevronDown } from '@lightspeed/assets/icons/chevron-down';
import { TypographyTokens } from '@lightspeed/styles/primary/tokens/typography';

export interface SelectProps {
  className?: string;
  disabled?: boolean;
  value: string;
  onChange: ChangeEventHandler<HTMLSelectElement>;
  placeholder?: string;
  options?: Array<SelectOption>;
  hasError?: boolean;
  onFocus?: FocusEventHandler<HTMLSelectElement>;
  onBlur?: () => void;
  name?: string,
};

export interface SelectOption {
  key?: string;
  label: string;
  value: string;
  disabled?: boolean;
  hidden?: boolean;
}

export const Select = ({
  className,
  value,
  onChange,
  placeholder = 'Select an option',
  options = [],
  disabled = false,
  hasError = false,
  onFocus,
  onBlur = () => {},
  name,
}: SelectProps) => (
  <DropDown
    name={name}
    placeholder={placeholder}
    value={value}
    disabled={disabled}
    onChange={onChange}
    hasError={hasError}
    onFocus={onFocus}
    onBlur={onBlur}
  >
    {
      options && options.map(
        ({
          label, hidden = false, value: optionVal, disabled: optionDisabled,
        }: SelectOption) => (
          <option
            key={label}
            value={optionVal}
            hidden={hidden}
            disabled={optionDisabled}
          >
            {label}
          </option>
        ),
      )
    }
  </DropDown>

);

const ChevronDownSvg = encodeURIComponent(renderToStaticMarkup(<ChevronDown />));

export const DropDown: any = styled.select.attrs<SelectProps>((
  {
    className,
    disabled,
    hasError,
    ...props
  },
) => ({
  className: cx(className, {
    'has-error': hasError,
    'is-disabled': disabled,
  }),
  disabled,
  ...props,
}))`
  appearance: none;
  font-family: ${ TypographyTokens.Gotham.book };
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.04em;
  height: 50px;
  width: 100%;
  border: 1px solid #E5E6EE;
  border-radius: 12px;
  padding: 15px 35px 15px 20px;

  --chevron:url('data:image/svg+xml;utf8, ${ChevronDownSvg}');
  background: var(--chevron) 92% center no-repeat ${(props) => props.theme.colors.white[0]};

  ${(props) => `color: ${props.value === '' ? '#C4C6D1' : '#1D1D23'};`}

  &.has-error {
    border: 1px solid ${(props) => props.theme.colors.error};
  }
  &:focus-visible {
    outline: 1px solid ${(props) => props.theme.colors.blue[0]};
  }

  option {
    all: unset;
    font-family: ${ TypographyTokens.Gotham.book };
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    display: block;
    cursor: pointer;
    box-sizing: border-box;
    padding: 5px 20px;
    width: 100%;
    color: #1D1D23;
    background-color: ${(props) => props.theme.colors.white[0]};

    &:focus-visible {
      outline: 1px solid ${(props) => props.theme.colors.blue[0]};
    }
    &:hover {
      background-color: ${(props) => props.theme.colors.gray[0]};
    }
  }
`;
