import React from 'react';
import { allPropertiesExist } from '@lightspeed/utils/type-assertions';
import { Heading, TextInput, OptionalElement, Label } from '@lightspeed/components/ui/atoms';
import { Select } from '@lightspeed/components/ui/atoms/select';
import { SlideToggle } from '@lightspeed/components/ui/atoms/slide-toggle/slide-toggle';
import { ValidationError } from '@lightspeed/components/ui/molecules/validation-error/validation-error';
import { Form, FormRow } from '@lightspeed/components/ui/templates/form/form';
import { STATE_OPTIONS } from '@lightspeed/models/us-states';
import { MortgageApplicationStore } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { NumberInput } from '@lightspeed/components/ui/molecules/number-input';
import { AddressAutocomplete, AddressAutocompleteOnChange, AddressAutocompleteWrapper, formatFeatureToString } from '@lightspeed/components/ui/atoms/address-autocomplete/address-autocomplete';

interface AddressFormProps {
  fireAnalyticsEvent: (event: string) => void;
  mortgageApplication: MortgageApplicationStore;
  updateMortgageApplication: (v: keyof MortgageApplicationStore, z: string) => void;
  errors: Map<string, string[]>|null;
  hasCoBorrower: boolean;
  coBorrowerHasSameAddress: boolean;
  setCoBorrowerHasSameAddress: React.Dispatch<React.SetStateAction<boolean>>;
}

export function AddressForm({
  mortgageApplication, updateMortgageApplication, errors, hasCoBorrower, coBorrowerHasSameAddress, setCoBorrowerHasSameAddress, fireAnalyticsEvent,
}: AddressFormProps) {
  const handleAutocompleteOnChange: AddressAutocompleteOnChange = (argument) => {
    if (argument && allPropertiesExist(argument)) {
      updateMortgageApplication('borrowerAddressAutocompleteValue', formatFeatureToString(argument) ?? '');
      updateMortgageApplication('borrowerStreetAddress', `${argument.streetNumber} ${argument.streetName}`);
      updateMortgageApplication('borrowerCity', `${argument.city}`);
      updateMortgageApplication('borrowerState', `${argument.state}`);
      updateMortgageApplication('borrowerZipCode', `${argument.zipcode}`);
      updateMortgageApplication('borrowerCounty', `${argument.county}`);
    } else {
      updateMortgageApplication('borrowerAddressAutocompleteValue', '');
      updateMortgageApplication('borrowerStreetAddress', '');
      updateMortgageApplication('borrowerCity', '');
      updateMortgageApplication('borrowerState', '');
      updateMortgageApplication('borrowerZipCode', '');
      updateMortgageApplication('borrowerCounty', '');
    }
  };

  return (
    <>
      <Form data-testid={'borrower-form'}>
        <Heading
          element={'h4'}
        >
          Current Address
        </Heading>
        <AddressAutocomplete
          fireAnalyticsEvent={fireAnalyticsEvent}
          id={'autocomplete-borrower'}
          initialSearchText={mortgageApplication.borrowerAddressAutocompleteValue}
          initialShowFallback={mortgageApplication.borrowerAddressAutocompleteValue === '' && !!mortgageApplication.borrowerState}
          onChange={handleAutocompleteOnChange}
          WrappingElement={AddressAutocompleteWrapper}
          placeholder={'123 Apple Creek Ln. Columbus, OH 43004'}
          fallbackUi={(
            <>
              <FormRow>
                <Label flex={1}>
                  Address*
                  <TextInput
                    name={'borrowerStreetAddress'}
                    value={mortgageApplication.borrowerStreetAddress}
                    onChange={(e) => { updateMortgageApplication('borrowerStreetAddress', e.target.value); }}
                    placeholder={'123 Main St.'}
                  />
                  <ValidationError
                    path={'borrowerStreetAddress'}
                    errors={errors}
                  />
                </Label>
                <Label flex={1}>
                  City*
                  <TextInput
                    name={'borrowerCity'}
                    value={mortgageApplication.borrowerCity}
                    onChange={(e) => { updateMortgageApplication('borrowerCity', e.target.value); }}
                    placeholder={'e.g. Columbus'}
                  />
                  <ValidationError
                    path={'borrowerCity'}
                    errors={errors}
                  />
                </Label>
              </FormRow>
              <FormRow>
                <Label flex={['0 0 100%', 1]}>
                  State*
                  <Select
                    name={'state-select'}
                    placeholder={'Select One'}
                    options={[{
                      label: 'Select One',
                      value: '',
                    }, ...STATE_OPTIONS]}
                    value={mortgageApplication.borrowerState}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateMortgageApplication('borrowerState', e.target.value)}
                  />
                  <ValidationError
                    path={'borrowerState'}
                    errors={errors}
                  />
                </Label>
                <Label flex={1}>
                  Zip Code*
                  <TextInput
                    inputMode={'numeric'}
                    name={'borrowerZipCode'}
                    value={mortgageApplication.borrowerZipCode}
                    onChange={(e) => { updateMortgageApplication('borrowerZipCode', e.target.value); }}
                    placeholder={'43004'}
                  />
                  <ValidationError
                    path={'borrowerZipCode'}
                    errors={errors}
                  />
                </Label>
              </FormRow>
            </>
          )}
        />
        <FormRow>
          <Label flex={1}>
            Years at Address*
            <NumberInput
              autoComplete={'off'}
              inputMode={'numeric'}
              name={'borrowerYearsAtAddress'}
              value={mortgageApplication.borrowerYearsAtAddress}
              onChange={(e) => { updateMortgageApplication('borrowerYearsAtAddress', e.target.value); }}
              placeholder={'0'}
            />
            <ValidationError
              path={'borrowerYearsAtAddress'}
              errors={errors}
            />
          </Label>
        </FormRow>
      </Form>
      <OptionalElement show={hasCoBorrower}>
        <Label
          flexDirection={'row'}
          alignItems={'center'}
        >
          My co-borrower’s address is the same
          <SlideToggle
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateMortgageApplication('coBorrowerAddressAutocompleteValue', '');
              updateMortgageApplication('coBorrowerStreetAddress', '');
              updateMortgageApplication('coBorrowerCity', '');
              updateMortgageApplication('coBorrowerCounty', '');
              updateMortgageApplication('coBorrowerZipCode', '');
              updateMortgageApplication('coBorrowerState', '');
              setCoBorrowerHasSameAddress(e.target.checked);
            }}
            checked={coBorrowerHasSameAddress}
          />
        </Label>
      </OptionalElement>
      <OptionalElement show={hasCoBorrower && !coBorrowerHasSameAddress}>
        <Form data-testid={'coborrower-form'}>
          <Heading
            element={'h2'}
            marginBottom={'20px'}
            marginTop={'20px'}
          >
            Co-borrower Current Address
          </Heading>
          <AddressAutocomplete
            fireAnalyticsEvent={fireAnalyticsEvent}
            id={'autocomplete-coborrower'}
            initialSearchText={mortgageApplication.coBorrowerAddressAutocompleteValue}
            initialShowFallback={mortgageApplication.coBorrowerAddressAutocompleteValue === '' && !!mortgageApplication.coBorrowerState}
            onChange={(argument) => {
              if (argument && allPropertiesExist(argument)) {
                updateMortgageApplication('coBorrowerAddressAutocompleteValue', formatFeatureToString(argument) ?? '');
                updateMortgageApplication('coBorrowerStreetAddress', `${argument.streetNumber} ${argument.streetName}`);
                updateMortgageApplication('coBorrowerCity', `${argument.city}`);
                updateMortgageApplication('coBorrowerState', `${argument.state}`);
                updateMortgageApplication('coBorrowerZipCode', `${argument.zipcode}`);
                updateMortgageApplication('coBorrowerCounty', `${argument.county}`);
              } else {
                updateMortgageApplication('coBorrowerAddressAutocompleteValue', '');
                updateMortgageApplication('coBorrowerStreetAddress', '');
                updateMortgageApplication('coBorrowerCity', '');
                updateMortgageApplication('coBorrowerState', '');
                updateMortgageApplication('coBorrowerZipCode', '');
                updateMortgageApplication('coBorrowerCounty', '');
              }
            }}
            WrappingElement={AddressAutocompleteWrapper}
            placeholder={'123 Apple Creek Ln. Columbus, OH 43004'}
            fallbackUi={(
              <>
                <FormRow>
                  <Label flex={1}>
                    Address*
                    <TextInput
                      name={'coBorrowerStreetAddress'}
                      value={mortgageApplication.coBorrowerStreetAddress}
                      onChange={(e) => { updateMortgageApplication('coBorrowerStreetAddress', e.target.value); }}
                      placeholder={'123 Main St.'}
                    />
                    <ValidationError
                      path={'coBorrowerStreetAddress'}
                      errors={errors}
                    />
                  </Label>
                  <Label flex={1}>
                    City*
                    <TextInput
                      name={'coBorrowerCity'}
                      value={mortgageApplication.coBorrowerCity}
                      onChange={(e) => { updateMortgageApplication('coBorrowerCity', e.target.value); }}
                      placeholder={'e.g. Columbus'}
                    />
                    <ValidationError
                      path={'coBorrowerCity'}
                      errors={errors}
                    />
                  </Label>
                </FormRow>
                <FormRow>
                  <Label flex={['0 0 100%', 1]}>
                    State*
                    <Select
                      name={'coborrower-state-select'}
                      options={[{
                        label: 'Select One',
                        value: '',
                      }, ...STATE_OPTIONS]}
                      value={mortgageApplication.coBorrowerState}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => updateMortgageApplication('coBorrowerState', e.target.value)}
                    />
                    <ValidationError
                      path={'coBorrowerState'}
                      errors={errors}
                    />
                  </Label>
                  <Label flex={1}>
                    Zip Code*
                    <TextInput
                      inputMode={'numeric'}
                      name={'coBorrowerZipCode'}
                      value={mortgageApplication.coBorrowerZipCode}
                      onChange={(e) => { updateMortgageApplication('coBorrowerZipCode', e.target.value); }}
                      placeholder={'43004'}
                    />
                    <ValidationError
                      path={'coBorrowerZipCode'}
                      errors={errors}
                    />
                  </Label>
                </FormRow>
              </>
            )}
          />
        </Form>
      </OptionalElement>
    </>
  );
}
