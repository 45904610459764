
import { TypographyTokens } from './tokens/typography';
import { ColorTokens } from './tokens/colors';
import { TimingTokens } from './tokens/timings';
import { TransitionTokens } from './tokens/transitions';
import { EasingTokens } from './tokens/easings';

export const customMediaQuery = (min: string, max?: string, orientation?: 'portrait'|'landscape', ratio?: number) =>
  `@media (min-width: ${min})
    ${max ? `and (max-width: ${max})` : ''}
      ${orientation ? `and (orientation: ${ orientation })` : ''}
        ${ratio ? `and (-webkit-min-device-pixel-ratio: ${ ratio })` : ''}
  `;

const breakpoints = [
  '1024px',
]

export const theme = {
  borders: [
    0,
    '1px solid',
    '2px solid',
    '4px solid',
    '8px solid',
    '16px solid',
    '32px solid',
  ],
  breakpoints,
  card: {
    variations: {
      blue: {
        borderColor: '#0064FF',
      },
      green: {
        borderColor: '#00D200',
      },
      purple: {
        borderColor: '#6236FF',
      },
      teal: {
        borderColor: '#00DCE5',
      },
    },
  },
  colors: ColorTokens,
  contentWidth: '1440px',
  easings: EasingTokens,
  fontSizes: [9, 12, 16, 20, 28, 44, 60],
  fontWeights: {
    bold: 700,
    light: 300,
    medium: 500,
    regular: 400,
  },
  fonts: {
    body: 'Gotham-Book, sans-serif',
  },
  lineHeights: {
    body: 1.5,
    heading: 1.2,
  },
  maxWidths: [16, 32, 64, 96, 128, 256, 512, 768, 1024, 1440],
  media: {
    desktop: customMediaQuery(breakpoints[0]),
  },
  pill: {
    variations: {
      blue: {
        background: 'rgba(62, 146, 247, 0.1)',
        color: '#0064FF',
      },
      green: {
        background: 'rgba(95, 206, 61, 0.1)',
        color: '#00D200',
      },
      purple: {
        background: 'rgba(98, 54, 255, 0.1)',
        color: '#6236FF',
      },
      teal: {
        background: 'rgba(0, 220, 229, 0.1)',
        color: '#00DCE5',
      },
    },
  },
  radii: [12],
  shadows: {
    card: '0px 28px 32px -20px rgba(23, 23, 47, 0.08)',
    generic: '0px 24px 48px rgba(23, 23, 47, 0.06)',
  },
  sizes: [16, 32, 64, 128, 256, 512],
  space: [0, 8, 16, 24, 32, 48, 64, 96, 128, 192, 256, 384, 512],
  timings: TimingTokens,
  transitions: TransitionTokens,
  zIndices: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  ...TypographyTokens,
};

export type ThemeType = typeof theme;
