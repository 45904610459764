import styled from 'styled-components';
import { Symbol } from '@lightspeed/components/ui/atoms/symbol';

export const Header = () => (
  <Container>
    <CompanyLogo>
      <Symbol  type={'LowerLogo'} />
    </CompanyLogo>
    <CompanyLogoMobile>
      <Symbol type={'LowerLogoMobile'} />
    </CompanyLogoMobile>
    <AppLogo>
      <Symbol type={'PersonalApplyTM'} />
    </AppLogo>
    <NavIcon />
  </Container>
)

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  margin-bottom: 48px;
  padding: 24px 24px 0 24px;
  box-sizing: border-box;

  ${(props) => props.theme.media.desktop} {
    padding: 0 48px;
    height: 100px;
    align-items: center;
  }
`;

const CompanyLogo = styled.div`
  display: none;
  ${(props) => props.theme.media.desktop} {
    display: flex
  }
`;

const CompanyLogoMobile = styled.div`
  display: flex;
  padding: 10px;
  ${(props) => props.theme.media.desktop} {
    display: none;
  }
`;

const AppLogo = styled.div`
  display: none;
  ${(props) => props.theme.media.desktop} {
    position: absolute;
    left: 50%;
    display: block;
    transform: translateX(-50%);
  }
`;

// invisible flex item
const NavIcon = styled.div`
  display: flex;
`;
