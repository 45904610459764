import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Analytics } from '@lightspeed/utils/analytics';

export function useAnalytics(pageName: string) {
  const location = useLocation();

  useEffect(() => {
    Analytics.pageView(
      pageName,
      window.location.href,
      location.pathname,
    );
  }, [pageName, location.pathname]);

  // return a function that prefixes the events triggered with <pageName_
  return useCallback((eventName: string, eventMetaData: object = {}) => {
    Analytics.event(
      `${pageName}_${eventName}`,
      eventMetaData,
    );
  }, [pageName]);
}
