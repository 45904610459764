/**
 * This is a generic wrapper around google analytics 4's GTAG lib.
 * We can add other providers to this eventually. gtag gets set
 * in index.html, but we're going to mock it in test cases.
 */

export class Analytics {
  static event(eventName: string, eventMetaData: object = {}) {
    window.gtag('event', eventName, eventMetaData);
  }

  static pageView(pageTitle: string, pageLocation: string, pagePath: string) {
    window.gtag('event', 'page_view', {
      page_location: pageLocation,
      page_path: pagePath,
      page_title: pageTitle,
      send_to: window.ga_measurement_id,
    });
  }
}
