import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

type LottieSourceProps = {
  // CARE FOR WHAT YOU PASS IN
  lottieJSONFile: any;
  style?: React.CSSProperties;
  // true/false | set how many loops (number)
  // default true
  lottieLoop?: boolean | number;
}

export const LottieSource = ({
  lottieJSONFile, style = {}, lottieLoop = true,
}: LottieSourceProps) => {
  const lottieComponentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // https://github.com/airbnb/lottie-web/blob/cb1add8e78068ab36b6b7c3d052fd3ef6a7cf729/index.d.ts
    lottie.loadAnimation({
      animationData: lottieJSONFile,
      container: lottieComponentRef.current as HTMLDivElement,
      loop: lottieLoop,
      renderer: 'svg',
    });
  }, [lottieJSONFile, lottieLoop]);

  return (
    <div
      style={style}
      ref={lottieComponentRef}
    />
  );
}

