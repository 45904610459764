import { createContext, ReactNode, useContext } from 'react';
import { usePersonalizedLoanState, PersonalizedLoanState, PersonalizedLoanStore } from './use-personalized-loan-state';

const PersonalizedLoanContext = createContext<PersonalizedLoanState>({} as PersonalizedLoanState);

export function PersonalizedLoanContextProvider({ children, overrides }: { children: ReactNode|ReactNode[], overrides: Partial<PersonalizedLoanStore> }) {
  return (
    <PersonalizedLoanContext.Provider value={usePersonalizedLoanState(overrides)}>
      {children}
    </PersonalizedLoanContext.Provider>
  );
}

export function usePersonalizedLoan() {
  return useContext(PersonalizedLoanContext);
}
