import { OptionalElement, Paragraph } from '@lightspeed/components/ui/atoms';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';

export function RateDisclaimer() {
  const { mortgageApplication } = useMortgageApplication();
  return (
    <OptionalElement show={mortgageApplication.applicationType !== 'Heloc'}>
      <Paragraph variant={'legal'}>
        The stated interest rate may change or not be available at the time of loan commitment, lock-in, or closing. Your loan advisor will make these calculations for your specific property and profile&mdash;just give us a call at <b>(844) 64-LOWER</b>.
      </Paragraph>
    </OptionalElement>
  );
};
