import { useRef, useEffect } from 'react';
import { MortgageApplicationStore } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';

export function useFireBadStateGAEvent(
  mortgageApplication: MortgageApplicationStore,
  errors: Map<string, string[]>|null,
  fireAnalyticsEvent: (eventName: string, eventMetaData: object) => void,
) {
  const hasRun = useRef(false);

  useEffect(() => {
    if (!hasRun.current && errors?.has('propertyState')) {
      hasRun.current = true;
      fireAnalyticsEvent('bad_state', {
        state: mortgageApplication.propertyState,
      });
    }
  }, [errors, fireAnalyticsEvent, mortgageApplication.propertyState]);
}
