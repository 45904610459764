import { CardSelectOption } from '@lightspeed/components/ui/organisms';

export function getDownPaymentOptions(isFirstTimeHomeBuyer: boolean): CardSelectOption[] {
  const options: CardSelectOption[] = [
    {
      label: '5%',
      tag: {
        text: '$$',
        variant: 'blue',
      },
      value: '.05',
    },
    {
      label: '15%',
      tag: {
        text: '$$$',
        variant: 'purple',
      },
      value: '.15',
    },
    {
      label: '20%',
      tag: {
        text: '$$$$',
        variant: 'teal',
      },
      value: '.20',
    },
  ];

  if (isFirstTimeHomeBuyer) {
    options.unshift({
      label: '3%',
      tag: {
        text: '$',
        variant: 'green',
      },
      value: '.03',
    });
  }

  return options;
}
