import type { CreateApplicationLeadPayload } from './createApplicationLead';

export default function updateApplicationLead(leadId: string, payload: CreateApplicationLeadPayload) {
  return fetch(`${process.env.API_HOST}/api/v1/Lead/ApplicationLead/${leadId}`, {
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
  });
}
