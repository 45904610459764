import styled from 'styled-components';
import { FontWeightProps, LayoutProps, SpaceProps } from 'styled-system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TypographyTokens } from '@lightspeed/styles/primary/tokens/typography';

const ButtonGroupWrapper = styled.div`
  background-color: #001CDB;
  width: 324px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  box-shadow: 0px 14px 36px rgba(0, 3, 47, 0.15);
  margin-top: 39px;
  margin-bottom: 38px;
  align-items: center;
  div:nth-of-type(3) {
    display: none !important;
  }
  button:nth-of-type(1) {
    border: 0px;
    background-color: #001CDB;
    border-radius: 30px 0px 0px 30px;
    height: 4em;
    width: 8em;
    cursor: pointer;
  }
  button:nth-of-type(2) {
    border: 0px;
    background-color: #001CDB;
    border-radius: 0;
    height: 4em;
    width: 8em;
    cursor: pointer;
  }
  button:nth-of-type(3) {
    border: 0px;
    background-color: #001CDB;
    border-radius: 0px 30px 30px 0px;
    height: 4em;
    width: 8em;
    cursor: pointer;
  }
`;

const ButtonLink = styled.button`
  font-family: ${ TypographyTokens.Gotham.bold };
  font-size: 14px;
`;

const Divider = styled.div`
  border: 1px solid #6877DF;
  width: 0px;
  height: 44px;
  margin: 0px;
  padding: 0px;
`;

export type ButtonLinkProps = {
  buttonLabel: string;
  link: string;
}

export type ButtonGroupProps = {
  buttons: ButtonLinkProps[];
} & FontWeightProps & LayoutProps & SpaceProps;

export function ButtonGroup({ buttons, ...props }: ButtonGroupProps) {
  /**
   * In general, we should not call useNavigate directly. We should
   * prefer useNextRoute, but the routing machine code was weird
   * here due to the nature of asyncronous setStates in the
   * navigate functions.  We chose to just navigate instead
   * here to keep things simple.
   */
  const navigate = useNavigate();
  return (
    <div>
      <ButtonGroupWrapper>
        {buttons.map((button) => (
          <React.Fragment key={button.buttonLabel}>
            <ButtonLink
              style={{
                color: '#fff',
                display: 'flex !important',
              }}
              onClick={() => navigate(button.link)}
            >
              {button.buttonLabel}
            </ButtonLink>
            <Divider />
          </React.Fragment>
        ))}
      </ButtonGroupWrapper>
    </div>
  )
};
