import React, { useState } from 'react';
import { Button, Heading, OptionalElement, Paragraph } from '@lightspeed/components/ui/atoms';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { BasePageTemplate } from '@lightspeed/components/ui/templates';
import { Page } from '@lightspeed/components/ui/templates/base-page-template/page';
import { SplitSection } from '@lightspeed/components/ui/templates/split-section';
import { Scoreboard } from '@lightspeed/components/ui/molecules/scoreboard/scoreboard';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { useAnalytics } from '@lightspeed/hooks/useAnalytics/useAnalytics';
import {
  MortgageApplicationStore,
  MortgageApplicationStoreKeys,
} from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { getButtonDisabledState } from '@lightspeed/utils/get-button-disabled-state';
import { useValidation } from '@lightspeed/hooks/useValidation/useValidation';
import {
  mortgageApplicationSchema,
} from '@lightspeed/contexts/mortgage-application-context/mortage-application-validation';
import { Explainer } from '@lightspeed/components/ui/molecules/explainer/explainer';
import { useMortgageContextDefault } from '@lightspeed/hooks/useMortgageContextDefaults/useMortgageContextDefaults';
import { useApplicationLead } from '@lightspeed/hooks/useApplicationLead/useApplicationLead';
import { LoadingModal } from '@lightspeed/components/ui/molecules';
import { getFormValues } from '@lightspeed/utils/get-form-values';
import { EmployerForm } from './employer-form';
import { IncomeForm } from './income-form';

const formatter = new Intl.NumberFormat('en-US', {
  currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency',
});

function getTotalIncome(mortgageApplication: MortgageApplicationStore): string {
  const income = [
    mortgageApplication.borrowerOtherIncomePerYear || '0',
    mortgageApplication.borrowerYearlySalary || '0',
    mortgageApplication.coBorrowerYearlySalary || '0',
    mortgageApplication.coBorrowerOtherIncomePerYear || '0',
  ].map<number>((stringNumber) => parseInt(stringNumber, 10))
    .reduce((acc, current) => acc + current, 0);

  return formatter.format(income).slice(1);
}

const borrowerKeys: MortgageApplicationStoreKeys[] = [
  'borrowerYearlySalary',
  'borrowerOtherIncomePerYear',
  'borrowerEmployer',
  'borrowerEmploymentStatus',
  'borrowerYearsAtCompany',
  'borrowerTitle',
];

const coBorrowerKeys: MortgageApplicationStoreKeys[] = [
  'coBorrowerYearlySalary',
  'coBorrowerOtherIncomePerYear',
  'coBorrowerEmploymentStatus',
];

const sanitizeNumberField = (field: HTMLInputElement) => {
  if (field.getAttribute('inputmode') === 'decimal') {
    return field.value.replaceAll(/[$.,]/g, '');
  }

  return field.value;
}

export function Income() {
  const { updateMortgageApplication, mortgageApplication } = useMortgageApplication();
  const goToNextRoute = useNextRoute(mortgageApplication);
  const fireAnalyticsEvent = useAnalytics('v2/income');

  const hasCoBorrower = mortgageApplication.coBorrowerFirstName !== '';
  const { upsertLead } = useApplicationLead();
  const [showLoadingModal, setShowLoadingModal] = useState(false);

  useMortgageContextDefault('borrowerEmploymentStatus');
  useMortgageContextDefault('borrowerOtherIncomePerYear');
  useMortgageContextDefault('coBorrowerEmploymentStatus', hasCoBorrower);
  useMortgageContextDefault('coBorrowerOtherIncomePerYear', hasCoBorrower);

  const [validate, errors] = useValidation(mortgageApplicationSchema, {
    keysToValidate: hasCoBorrower ? [...borrowerKeys, ...coBorrowerKeys] : borrowerKeys,
    yupValidationContext: {
      hasCoBorrower,
    },
  });

  const disableNextButton = getButtonDisabledState<MortgageApplicationStore>(
    mortgageApplication,
    hasCoBorrower ? [...borrowerKeys, ...coBorrowerKeys] : borrowerKeys,
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const values = getFormValues(e.currentTarget, sanitizeNumberField);

    if (validate({
      ...mortgageApplication,
      ...values,
    }).success) {
      updateMortgageApplication(values);

      setShowLoadingModal(true);
      try {
        await upsertLead();
        setShowLoadingModal(false);
      } catch (_) {} // eslint-disable-line no-empty

      fireAnalyticsEvent('next_button_success');
      goToNextRoute();
    }
  };

  return (
    <>
      <OptionalElement show={showLoadingModal}>
        <LoadingModal />
      </OptionalElement>
      <Page
        desktop={(
          <>
            <Header />
            <form onSubmit={handleSubmit}>
              <BasePageTemplate>
                <SplitSection>
                  <SplitSection.Content>
                    <Heading
                      element={'h1'}
                    >Let&apos;s talk income.
                    </Heading>
                    <IncomeForm
                      mortgageApplication={mortgageApplication}
                      updateMortgageApplication={updateMortgageApplication}
                      errors={errors}
                    />
                    <EmployerForm
                      mortgageApplication={mortgageApplication}
                      updateMortgageApplication={updateMortgageApplication}
                      errors={errors}
                    />
                    <SplitSection.LegalFooter>
                      <Explainer
                        label={'Why do we ask?'}
                      >
                        <Paragraph variant={'smallLight'}>
                          It’s no secret that to buy a house, you need to make money. Knowing your income helps us calculate your debt-to-income ratio, which is key to qualifying you for a loan and getting you a lower rate.
                        </Paragraph>
                      </Explainer>
                    </SplitSection.LegalFooter>
                  </SplitSection.Content>
                  <SplitSection.Sidebar>
                    <Scoreboard
                      marginY={'36px'}
                      label={'Total'}
                      prefix={'$'}
                      value={getTotalIncome(mortgageApplication)}
                    />
                    <Button
                      marginTop={'48px'}
                      type={'submit'}
                      disabled={disableNextButton}
                    >
                      Next
                    </Button>
                  </SplitSection.Sidebar>
                </SplitSection>
              </BasePageTemplate>
            </form>
          </>
        )}
        mobile={(
          <>
            <Header />
            <form onSubmit={handleSubmit}>
              <BasePageTemplate>
                <Heading
                  element={'h1'}
                >
                  Let&apos;s talk income.
                </Heading>
                <IncomeForm
                  mortgageApplication={mortgageApplication}
                  updateMortgageApplication={updateMortgageApplication}
                  errors={errors}
                />
                <Scoreboard
                  marginY={'36px'}
                  label={'Total'}
                  prefix={'$'}
                  value={getTotalIncome(mortgageApplication)}
                />
                <EmployerForm
                  mortgageApplication={mortgageApplication}
                  updateMortgageApplication={updateMortgageApplication}
                  errors={errors}
                />
                <Explainer
                  label={'Why do we ask?'}
                >
                  <Paragraph variant={'smallLight'}>
                    It’s no secret that to buy a house, you need to make money. Knowing your income helps us calculate your debt-to-income ratio, which is key to qualifying you for a loan and getting you a lower rate.
                  </Paragraph>
                </Explainer>
                <Button
                  disabled={disableNextButton}
                  type={'submit'}
                  marginY={'60px'}
                >
                  Next
                </Button>
              </BasePageTemplate>
            </form>
          </>
        )}
      />
    </>
  );
}
