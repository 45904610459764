import { FormattedGoogleAddress } from '@lightspeed/models/google-maps';

export function getFormattedAddressFromPlaceResult(result: google.maps.places.PlaceResult): FormattedGoogleAddress {
  const address: FormattedGoogleAddress = {};
  result?.address_components?.forEach((element) => {
    if (element.types.includes('street_number')) {
      address.streetNumber = element.short_name;
    }
    if (element.types.includes('route')) {
      address.streetName = element.long_name;
    }
    if (element.types.includes('locality')) {
      address.city = element.short_name;
    }
    if (element.types.includes('administrative_area_level_1')) {
      address.state = element.long_name;
      address.stateAbbreviation = element.short_name;
    }
    if (element.types.includes('administrative_area_level_2')) {
      address.county = element.short_name.replace(' County', '');
    }
    if (element.types.includes('postal_code')) {
      address.zipcode = element.short_name;
    }
  });
  return address;
}
