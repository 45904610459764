import { createContext, ReactNode, useContext } from 'react';
import { useMortgageApplicationState, MortgageApplicationState, MortgageApplicationStore } from './use-mortgage-application-state';

const MortgageContext = createContext<MortgageApplicationState>({} as MortgageApplicationState);

export function MortgageContextProvider({ children, overrides }: { children: ReactNode|ReactNode[], overrides: Partial<MortgageApplicationStore> }) {
  return (
    <MortgageContext.Provider value={useMortgageApplicationState(overrides)}>
      {children}
    </MortgageContext.Provider>
  );
}

export function useMortgageApplication() {
  return useContext(MortgageContext);
}
