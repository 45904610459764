import React from 'react';
import { Heading, Label, OptionalElement, SSNInput, SlideToggle } from '@lightspeed/components/ui/atoms';
import { DateInput } from '@lightspeed/components/ui/molecules';
import { ValidationError } from '@lightspeed/components/ui/molecules/validation-error/validation-error';
import { Form, FormRow } from '@lightspeed/components/ui/templates/form/form';
import { MortgageApplicationStore, MortgageApplicationStoreKeys } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { GreenCheck } from './GreenCheck';

interface IdentityFormProps {
  mortgageApplication: MortgageApplicationStore;
  updateMortgageApplication: (key: MortgageApplicationStoreKeys, value: string) => void;
  errors: Map<string, string[]>|null;
}

export function IdentityForm({
  mortgageApplication, updateMortgageApplication, errors,
}: IdentityFormProps) {
  return (
    <>
      <Form>
        <Heading element={'h4'}>Soft Credit Check</Heading>
        <FormRow>
          <Label flex={['0 0 100%', 1]}>
            Social Security Number*
            <SSNInput
              name={'ssn'}
              placeholder={'XXX-XX-XXXX'}
              value={mortgageApplication.borrowerSocialSecurityNumber}
              onChange={(e) => updateMortgageApplication('borrowerSocialSecurityNumber', e.target.value)}
            />
            <ValidationError
              errors={errors}
              path={'borrowerSocialSecurityNumber'}
            />
          </Label>
          <Label flex={['0 0 100%', 1]}>
            Date of Birth*
            <DateInput
              name={'borrower-date-of-birth'}
              value={mortgageApplication.borrowerDateOfBirth}
              onChange={(e) => updateMortgageApplication('borrowerDateOfBirth', e.target.value)}
            />
            <ValidationError
              errors={errors}
              path={'borrowerDateOfBirth'}
            />
          </Label>
        </FormRow>
        <FormRow>
          <Label
            flex={['0 0 100%', '0 0 50%']}
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <span
              style={{
                alignItems: 'center',
                display: 'flex',
                fontSize: '14px',
                gap: '10px',
              }}
            >
              <GreenCheck
                circleColor={mortgageApplication.borrowerCreditAuthDateTime ? '#00D200' : '#e5e6ee'}
                checkColor={mortgageApplication.borrowerCreditAuthDateTime ? '#fff' : '#c4c6d1'}
              />
              I agree to a soft credit check
            </span>
            <SlideToggle
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.checked ? (new Date()).toISOString() : '';
                updateMortgageApplication('borrowerCreditAuthDateTime', value);
              }}
              checked={mortgageApplication.borrowerCreditAuthDateTime !== ''}
            />
          </Label>
        </FormRow>
      </Form>
      <OptionalElement show={mortgageApplication.coBorrowerFirstName !== ''}>
        <Form>
          <Heading element={'h4'}>Co-borrower Soft Credit Check</Heading>
          <FormRow>
            <Label flex={['0 0 100%', 1]}>
              Social Security Number*
              <SSNInput
                placeholder={'XXX-XX-XXXX'}
                value={mortgageApplication.coBorrowerSocialSecurityNumber}
                onChange={(e) => updateMortgageApplication('coBorrowerSocialSecurityNumber', e.target.value)}
              />
              <ValidationError
                errors={errors}
                path={'coBorrowerSocialSecurityNumber'}
              />
            </Label>
            <Label flex={['0 0 100%', 1]}>
              Date of Birth*
              <DateInput
                name={'coBorrower-date-of-birth'}
                value={mortgageApplication.coBorrowerDateOfBirth}
                onChange={(e) => updateMortgageApplication('coBorrowerDateOfBirth', e.target.value)}
              />
              <ValidationError
                errors={errors}
                path={'coBorrowerDateOfBirth'}
              />
            </Label>
          </FormRow>
          <FormRow>
            <Label
              flex={['0 0 100%', '0 0 50%']}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <span
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  fontSize: '14px',
                  gap: '10px',
                }}
              >
                <GreenCheck
                  circleColor={mortgageApplication.coBorrowerCreditAuthDateTime ? '#00D200' : '#e5e6ee'}
                  checkColor={mortgageApplication.coBorrowerCreditAuthDateTime ? '#fff' : '#c4c6d1'}
                />
                I agree to a soft credit check
              </span>
              <SlideToggle
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.checked ? (new Date()).toISOString() : '';
                  updateMortgageApplication('coBorrowerCreditAuthDateTime', value);
                }}
                checked={mortgageApplication.coBorrowerCreditAuthDateTime !== ''}
              />
            </Label>
          </FormRow>
        </Form>
      </OptionalElement>
    </>
  );
}
