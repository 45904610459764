import styled from 'styled-components';
import { compose, fontWeight, FontWeightProps, layout, LayoutProps, space, SpaceProps } from 'styled-system';
import React from 'react';
import cx from 'classnames';

export type CircleButtonProps = {
  active?: boolean;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'button' | 'submit';
  variant?: 'default' | 'selected';
} & FontWeightProps & LayoutProps & SpaceProps;

export const CircleButton = styled.button.attrs(({
  className,
  type = 'button',
  variant = 'default',
  ...props
}: CircleButtonProps) => ({
  className: cx('Button', className, {
    '--default': variant === 'default',
    'is-disabled': props.disabled,
    'is-selected': props.selected,
  }),
  type,
  variant,
  ...props,
}))`
  /* Reset */
  all: unset;
  cursor: pointer;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 16px;
  line-height: 16px !important;

  ${ (props) => props.theme.Button.bold };
  border-radius: 50%;
  border: 1px solid ${ ({ theme }) => theme.colors.silk };


  &.--default {
    color: ${ ({ theme }) => theme.colors.charcoal };
    background-color: ${ ({ theme }) => theme.colors.white[0] };

    &:hover:not(:active):not(.is-disabled):not(:disabled) {
      cursor: pointer;
      transform: scale(1.05);
      border: 2px solid ${ ({ theme }) => theme.colors.blue[0] };
      box-shadow: ${ (props) => props.theme.shadows.generic };
    }

    &:active:not(.is-disabled):not(:disabled) {
      transform: scale(.95)
      border: 2px solid ${ ({ theme }) => theme.colors.blue[0] };
      box-shadow: ${ (props) => props.theme.shadows.generic };
    }
  }

  &.is-selected {
    transform: scale(.95);
    color: ${ ({ theme }) => theme.colors.blue[0] };
    background-color: ${ ({ theme }) => theme.colors.white[0] };
    border: 2px solid ${ ({ theme }) => theme.colors.blue[0] };
    box-shadow: ${ (props) => props.theme.shadows.generic };
  }

  &.is-disabled, &:disabled {
    cursor: not-allowed;
    color: ${ (props) => props.theme.colors.silk };
    border: 1px solid ${ ({ theme }) => theme.colors.silk };
    box-shadow: none;
  }

  &:focus-visible {
    border: 1px solid ${ (props) => props.theme.colors.blue[0] };
  }

  ${ compose(layout, space, fontWeight) };
`;
