import React from 'react';
import styled from 'styled-components';
import { Label, CircleButton, Symbol } from '@lightspeed/components/ui/atoms';
import { responsiveStyleFromTheme } from '@lightspeed/styles/utils/theme-utils';
import { ToolTip } from '@lightspeed/components/ui/molecules';

export interface CircleButtonSelectProps {
  onChange: (value: string) => void;
  value: string;
  options: CircleButtonSelectOption[];
  label: {
    text: string;
    tooltip?: string;
  };
}

export interface CircleButtonSelectOption {
  label: string;
  value: string;
}

export const CircleButtonSelect = ({
  label,
  options,
  value,
  onChange,
  ...props
}: CircleButtonSelectProps) => (
  <Container {...props}>
    <Label marginBottom={'16px'}>
      <span>
        {label.text}
        {
          label.tooltip && (
            <ToolTip bubble={label.tooltip}>
              <Symbol type={'Info'} />
            </ToolTip>
          )}
      </span>
    </Label>
    <ButtonsContainer>
      {
        options.map(
          (option, index) => (
            <CircleButton
              type={'button'}
              key={index}
              onClick={() => onChange(option.value)}
              selected={option.value === value}
            >
              {option.value}
            </CircleButton>
          ),
        )
      }
    </ButtonsContainer>
  </Container>
);

const Container = styled.div``;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${responsiveStyleFromTheme({
  desktop: {
    gap: '20px',
  },
})}
`;
