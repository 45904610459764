import { ComponentProps } from 'react';
import styled from 'styled-components';
import { color, compose } from 'styled-system';
import type { ColorProps } from 'styled-system';

type BaseLinkProps = {
  bold?: boolean;
} & ColorProps

export const Link = styled.a<BaseLinkProps & ColorProps>`
  display: block;
  ${ (props) => props.theme.Link[props.bold ? 'bold' : 'book'] };
  color: ${ (props) => props.theme.colors.blue[0] };
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  ${ compose(color) };
`;

export type LabelProps = ComponentProps<typeof Link>
