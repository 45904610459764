import { ReactNode } from 'react';
import styled from 'styled-components';
import { compose, layout, LayoutProps, shadow, ShadowProps, space, SpaceProps } from 'styled-system';
import { Heading, Pill } from '@lightspeed/components/ui/atoms';
import { PillProps } from '@lightspeed/components/ui/atoms/pill/pill';
import { HeadingProps } from '@lightspeed/components/ui/atoms/heading/heading';
import { BoxProps } from '@lightspeed/components/ui/atoms/box/box';

export type CardButtonProps = {
  $active?: boolean;
  $variant?: 'blue' | 'teal' | 'purple' | 'green';
} & LayoutProps & SpaceProps & ShadowProps;

export interface CardProps extends BoxProps {
  children?: ReactNode;
  className?: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  variant?: 'blue' | 'teal' | 'purple' | 'green';
}

export const Card = ({
  active,
  disabled,
  children,
  className,
  onClick,
  variant,
  ...props
}: CardProps) => (
  <CardButton
    type={'button'}
    p={2}
    $active={active}
    $variant={variant}
    onClick={disabled ? undefined : onClick}
    disabled={disabled}
    {...props}
  >
    {children && children}
  </CardButton>
)

const CardButton = styled.button<CardButtonProps>`
  all: unset;
  flex: 1;
  position: relative;
  background-color: #fff;
  border: 1px solid #E5E6EE;
  border-radius: 12px;
  transition:
    transform 400ms ${ (props) => props.theme.easings.easeOutCubic },
    box-shadow 100ms ease-in-out;

  &:hover {
    cursor: pointer;
    transform: scale(0.95) translateY(-5px);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }

  ${({
  $active, $variant, theme,
}) => $active && `
    border-radius: 12px;
    box-shadow: 0 24px 48px rgba(23, 23, 47, 0.06);
    transition:
      opacity ${theme.transitions.prop },
      transform 400ms ${theme.easings.easeOutCubic },
      box-shadow 100ms ease-in-out;
    border: 1px solid ${$variant ? theme.card.variations[$variant].borderColor : theme.colors.brightBlue };
    &:after {
      opacity: 1;
    }
  `}

  ${ compose(layout, space, shadow) };
`;

Card.Pill = (props: PillProps) => (
  <Pill
    data-test-id={'cardPill'}
    marginBottom={2}
    {...props}
  />
);

Card.Text = (props: HeadingProps) => (
  <Heading
    element={'h5'}
    {...props}
  />
);
