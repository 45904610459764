
export const ChevronDown = ({ ...props }) => (
  <svg
    width={'9'}
    height={'6'}
    viewBox={'0 0 9 6'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    {...props}
  >
    <path
      d={'M1 1L4.5 5C5.86683 3.4379 6.63317 2.5621 8 1'}
      stroke={'#001CDB'}
      strokeWidth={'2'}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
    />
  </svg>
)
