import React from 'react';

export function GreenCheck({ circleColor, checkColor }: { circleColor: string; checkColor: string; }) {
  return (
    <svg
      width={'20'}
      height={'20'}
      viewBox={'0 0 20 20'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <circle
        fill={circleColor}
        cx={'10'}
        cy={'10'}
        r={'10'}
      />
      <path
        fill={checkColor}
        fillRule={'evenodd'}
        clipRule={'evenodd'}
        d={'M12.7818 7.33594L14 8.46012L9.13781 14.0026L6 10.9597L7.12585 9.73846L9.04312 11.5977L12.7818 7.33594Z'}
      />
    </svg>
  );
}
