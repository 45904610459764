import cx from 'classnames';
import styled, { keyframes } from 'styled-components';
import { MouseEvent, ReactNode } from 'react';
import { CSSObject } from '@styled-system/css';

export interface ToolTipProps {
  children: ReactNode|ReactNode[];
  bubble: ReactNode|ReactNode[];
  className?: string;
  as?: 'div'|'span'|'p'|'a';
  bubbleStyle?: CSSObject;
}

export const ToolTip = ({
  children,
  bubble,
  className,
  as = 'span',
  bubbleStyle = {},
  ...props
}: ToolTipProps) => (
  <Container
    as={as}
    className={cx('ToolTip', className)}
    onClick={(e: MouseEvent<HTMLDivElement | HTMLSpanElement | HTMLParagraphElement | HTMLAnchorElement>) => { e.preventDefault() }}
    {...props}
  >
    {children}
    <TipContainer
      data-testid={'tip-container'}
      style={bubbleStyle}
    >
      {bubble}
    </TipContainer>
  </Container>
)

const Container = styled.div`
  position: relative;
  margin-top: -5px;
  margin-left: 4px;
  cursor: pointer;
  ${(props) => props.theme.media.desktop} {
    top: 0;
  }
`;

const FadeInKeyframes = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1;
  }
`;

const TipContainer = styled.span`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: -90px;
  padding: 10px;
  width: 200px;
  max-width: 300px;
  border: 1px solid #E5E6EE;
  box-sizing: border-box;
  border-radius: 18px;
  background-color: #F3F3F8;
  color: #1C1C1E;
  text-align: left;
  font-size: 12px;
  transition: opacity 0.3s;
  font-weight: normal;

  ${(props) => props.theme.media.desktop} {
    bottom: 150%;
    left: -142px;
    padding: 20px;
    width: 300px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #E5E6EE transparent transparent transparent;
  }

  ${Container}:hover & {
    visibility: visible;
    opacity: 1;
    animation: ${FadeInKeyframes} 200ms ease-in-out;
  }
`;
