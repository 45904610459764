import { useState } from 'react';
import { Button, OptionalElement, Paragraph } from '@lightspeed/components/ui/atoms';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { Page } from '@lightspeed/components/ui/templates/base-page-template/page';
import { Scoreboard } from '@lightspeed/components/ui/molecules/scoreboard/scoreboard';
import { BasePageTemplate   } from '@lightspeed/components/ui/templates';
import { SplitSection } from '@lightspeed/components/ui/templates/split-section';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { MortgageApplicationStore, MortgageApplicationStoreKeys } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { useAnalytics } from '@lightspeed/hooks/useAnalytics/useAnalytics';
import { useValidation } from '@lightspeed/hooks/useValidation/useValidation';
import { mortgageApplicationSchema } from '@lightspeed/contexts/mortgage-application-context/mortage-application-validation';
import { getButtonDisabledState } from '@lightspeed/utils/get-button-disabled-state';
import { Explainer } from '@lightspeed/components/ui/molecules/explainer/explainer';
import { useMortgageContextDefault } from '@lightspeed/hooks/useMortgageContextDefaults/useMortgageContextDefaults';
import { useApplicationLead } from '@lightspeed/hooks/useApplicationLead/useApplicationLead';
import { LoadingModal } from '@lightspeed/components/ui/molecules';
import { YourAssetsForm } from './your-assets-form';

const borrowerKeys: MortgageApplicationStoreKeys[] = [
  'borrowerAmountInSavings',

];

const coBorrowerKeys: MortgageApplicationStoreKeys[] = [
  'coBorrowerAmountInSavings',
];

const formatter = new Intl.NumberFormat('en-US', {
  currency: 'USD', maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency',
});

const calculateAssetsTotal = (mortgageApplication : MortgageApplicationStore) => {
  // convert strings to num and default to zero if empty
  const borrowerAmountInSavingsNum = mortgageApplication.borrowerAmountInSavings ? parseInt(mortgageApplication.borrowerAmountInSavings, 10) : 0;
  const borrowerAmountInMutualFundsNum = mortgageApplication.borrowerAmountInMutualFunds ? parseInt(mortgageApplication.borrowerAmountInMutualFunds, 10) : 0;
  const coBorrowerAmountInSavingsNum = mortgageApplication.coBorrowerAmountInSavings ? parseInt(mortgageApplication.coBorrowerAmountInSavings, 10) : 0;
  const coBorrowerAmountInMutualFundsNum = mortgageApplication.coBorrowerAmountInMutualFunds ? parseInt(mortgageApplication.coBorrowerAmountInMutualFunds, 10) : 0;
  const total = coBorrowerAmountInSavingsNum + coBorrowerAmountInMutualFundsNum + borrowerAmountInSavingsNum + borrowerAmountInMutualFundsNum
  return formatter.format(total).slice(1);
}

export function YourAssets() {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const goToNextRoute = useNextRoute(mortgageApplication);
  const fireAnalyticsEvent = useAnalytics('v2/assets');
  const { upsertLead } = useApplicationLead();
  const [showLoadingModal, setShowLoadingModal] = useState(false);

  const hasCoborrower = mortgageApplication.coBorrowerFirstName !== '';

  useMortgageContextDefault('borrowerAmountInMutualFunds');
  useMortgageContextDefault('coBorrowerAmountInMutualFunds', hasCoborrower);
  useMortgageContextDefault('coBorrowerAmountInSavings', hasCoborrower);

  const [validate, errors] = useValidation(mortgageApplicationSchema, {
    keysToValidate: hasCoborrower ? [...borrowerKeys, ...coBorrowerKeys] : borrowerKeys,
    yupValidationContext: {
      hasCoBorrower: hasCoborrower,
    },
  });

  const disableNextButton = getButtonDisabledState<MortgageApplicationStore>(
    mortgageApplication,
    hasCoborrower ? [...borrowerKeys, ...coBorrowerKeys] : borrowerKeys,
  );

  const onNextClick = async () => {
    if (validate(mortgageApplication).success) {
      setShowLoadingModal(true);

      try {
        await upsertLead();
        setShowLoadingModal(false);
      } catch (e) {} // eslint-disable-line no-empty

      fireAnalyticsEvent('next_button_success')
      goToNextRoute();
    }
  };

  return (
    <>
      <OptionalElement show={showLoadingModal}>
        <LoadingModal />
      </OptionalElement>
      <Page
        desktop={(
          <>
            <Header />
            <BasePageTemplate>
              <SplitSection>
                <SplitSection.Content>
                  <YourAssetsForm
                    mortgageApplication={mortgageApplication}
                    hasCoborrower={hasCoborrower}
                    updateMortgageApplication={updateMortgageApplication}
                    errors={errors}
                  />
                  <SplitSection.LegalFooter>
                    <Explainer
                      label={'What assets should I include?'}
                    >
                      <Paragraph variant={'smallLight'}>
                        Checking or savings, mutual funds, CDs, or readily tradable bonds or stocks that you can easily convert to cash. Any money you have in accounts that could be pulled out as cash should be listed.
                      </Paragraph>
                    </Explainer>
                  </SplitSection.LegalFooter>
                </SplitSection.Content>
                <SplitSection.Sidebar >
                  <Scoreboard
                    marginY={'36px'}
                    label={'Total'}
                    prefix={'$'}
                    value={calculateAssetsTotal(mortgageApplication)}
                  />
                  <Button
                    marginBottom={'32px'}
                    disabled={disableNextButton}
                    onClick={onNextClick}
                  >
                    Next
                  </Button>
                </SplitSection.Sidebar>
              </SplitSection>
            </BasePageTemplate>
          </>
        )}

        mobile={(
          <>
            <Header />
            <BasePageTemplate>
              <YourAssetsForm
                mortgageApplication={mortgageApplication}
                hasCoborrower={hasCoborrower}
                updateMortgageApplication={updateMortgageApplication}
                errors={errors}
              />
              <Scoreboard
                marginY={'32px'}
                label={'Total'}
                prefix={'$'}
                value={calculateAssetsTotal(mortgageApplication)}
              />
              <Explainer
                label={'What assets should I include?'}
              >
                <Paragraph variant={'smallLight'}>
                  Checking or savings, mutual funds, CDs, or readily tradable bonds or stocks that you can easily convert to cash. Any money you have in accounts that could be pulled out as cash should be listed.
                </Paragraph>
              </Explainer>
              <Button
                marginY={'32px'}
                disabled={disableNextButton}
                onClick={onNextClick}
              >
                Next
              </Button>
            </BasePageTemplate>
          </>
        )}
      />
    </>
  );
}
