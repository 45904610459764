import {
  BorrowerInfo,
  BorrowerPersonalInformation,
  CoBorrowerInfo,
  CoBorrowerPersonalInformation,
  FinancialInfo,
  GuidedExperience,
  Loan,
  Property,
  Rate,
} from '@lightspeed/api/services/leads/createApplicationLead';
import { determineLoanAmount } from '@lightspeed/utils/determine-loan-amount';
import { CONTRACT_STATUSES } from '@lightspeed/models/contract-statuses';
import { STATE_NAME_TO_ABBREVIATION } from '@lightspeed/models/us-states';
import { transformDateToISO } from '@lightspeed/utils/transform-date-to-iso';
import {
  MortgageApplicationStore,
} from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { IndividualPersonalizedLoan } from '@lightspeed/api/services/personalizedLoanProducts/bestavailable';
import { ReferralCookieData } from '@lightspeed/utils/get-referral-cookie-data';
import { EmploymentStatus } from '@lightspeed/types/income';
import {
  coborrowerEmploymentStatusLeadApiValues,
  employmentStatusLeadApiValues,
} from '@lightspeed/models/employment-status';

const LOAN_TERM_MAP: Record<string, 'Fifteen'|'Twenty'|'Thirty'> = {
  'fifteen': 'Fifteen',
  'thirty': 'Thirty',
  'twenty': 'Twenty',
};

const CONTRACT_STATUS_MAP: Record<CONTRACT_STATUSES, 'InContract'|'PlacedOffer'|'StillLooking'> = {
  [CONTRACT_STATUSES.inContract]: 'InContract',
  [CONTRACT_STATUSES.placedOffer]: 'PlacedOffer',
  [CONTRACT_STATUSES.homeShopping]: 'StillLooking',
  [CONTRACT_STATUSES.justStarting]: 'StillLooking',
};

const OCCUPANCY_MAP: Record<string, 'PrimaryResidence'|'SecondHome'|'Investment'> = {
  'Investment': 'Investment',
  'Primary Residence': 'PrimaryResidence',
  'Secondary Residence': 'SecondHome',
};

const PROPERTY_TYPE_MAP: Record<string, 'SingleFamily'|'Condo'|'Duplex'|'Triplex'|'Fourplex'> = {
  'Condo': 'Condo',
  'Duplex': 'Duplex',
  'Fourplex': 'Fourplex',
  'Single Family': 'SingleFamily',
  'Triplex': 'Triplex',
}

export const EMPLOYMENT_STATUS_MAP: Record<string, typeof employmentStatusLeadApiValues[number]> = {
  [EmploymentStatus.RETIRED]: 'Retired',
  [EmploymentStatus.SELF_EMPLOYED]: 'SelfEmployed',
  [EmploymentStatus.W2]: 'W2',
}

export const COBORROWER_EMPLOYMENT_STATUS_MAP: Record<string, typeof coborrowerEmploymentStatusLeadApiValues[number]> = {
  [EmploymentStatus.RETIRED]: 'Retired',
  [EmploymentStatus.SELF_EMPLOYED]: 'SelfEmployed',
  [EmploymentStatus.W2]: 'W2',
  [EmploymentStatus.UNEMPLOYED]: 'Unemployed',
}

export const buildApplication = (mortgageApplicationContext: MortgageApplicationStore, isComplete: boolean) => ({
  email: mortgageApplicationContext.borrowerEmail,
  firstName: mortgageApplicationContext.borrowerFirstName,
  isComplete,
  lastName: mortgageApplicationContext.borrowerLastName,
  type: 'guidedexperience',
});

export const buildAttribution = (mortgageApplicationContext: MortgageApplicationStore, referralCookieData: ReferralCookieData) => ({
  friendsAndFamilyReferralProgramEmail: referralCookieData.friends_and_family_referral_program_email,
  gclid: referralCookieData.gclid,
  isFromRateQuery: mortgageApplicationContext.fromRateQuery === 'true',
  lastReferrer: referralCookieData.referrer,
  loanOfficerToken: referralCookieData.loan_officer_token,
  utmCampaign: referralCookieData.utm_campaign,
  utmContent: referralCookieData.utm_content,
  utmMedium: referralCookieData.utm_medium,
  utmSource: referralCookieData.utm_source,
  utmTerm: referralCookieData.utm_term,
});

const buildLoan = (mortgageApplicationContext: MortgageApplicationStore): Loan => {
  const loan = {
    purpose: mortgageApplicationContext.applicationType,
  } as Loan;

  if (mortgageApplicationContext.propertyPurchasePrice || mortgageApplicationContext.propertyMortgageBalance) {
    loan.amount = determineLoanAmount(mortgageApplicationContext);
  }

  if (mortgageApplicationContext.applicationType === 'Refinance' && mortgageApplicationContext.propertyCashOut) {
    loan.cashOutAmount = parseFloat(mortgageApplicationContext.propertyCashOut);
  }

  if (mortgageApplicationContext.applicationType === 'Purchase' && mortgageApplicationContext.propertyDownPaymentPartiallyGift !== '') {
    loan.isDownPaymentPartiallyGift = mortgageApplicationContext.propertyDownPaymentPartiallyGift === 'Yes';
  }

  return loan;
};

const determinePropertyValue = (mortgageApplicationContext: MortgageApplicationStore): number => {
  if (mortgageApplicationContext.applicationType === 'Purchase') {
    return parseFloat(mortgageApplicationContext.propertyPurchasePrice)
  }
  return parseFloat(mortgageApplicationContext.propertyHomeValue)
}

const buildProperty = (mortgageApplicationContext: MortgageApplicationStore): Property | null => {
  const property = {} as Property;

  if (mortgageApplicationContext.applicationType === 'Purchase' && mortgageApplicationContext.borrowerContractStatus) {
    property.contractStatus = CONTRACT_STATUS_MAP[mortgageApplicationContext.borrowerContractStatus];
  }

  if (mortgageApplicationContext.propertyResidenceType) {
    property.occupancy = OCCUPANCY_MAP[mortgageApplicationContext.propertyResidenceType];
  }

  if (mortgageApplicationContext.propertyType) {
    property.propertyType = PROPERTY_TYPE_MAP[mortgageApplicationContext.propertyType];
  }

  if (mortgageApplicationContext.propertyPurchasePrice !== '' || mortgageApplicationContext.propertyHomeValue !== '') {
    property.value = determinePropertyValue(mortgageApplicationContext);
  }

  if (mortgageApplicationContext.propertyState) {
    property.address = {
      city: mortgageApplicationContext.propertyCity,
      county: mortgageApplicationContext.propertyCounty,
      state: STATE_NAME_TO_ABBREVIATION.get(mortgageApplicationContext.propertyState),
      street: mortgageApplicationContext.propertyStreetAddress,
      zipCode: mortgageApplicationContext.propertyZipCode,
    }
  }

  if (Object.keys(property).length > 0) {
    return property
  }
  return null;
}

const buildBorrowerPersonalInfo = (mortgageApplicationContext: MortgageApplicationStore): BorrowerPersonalInformation | null => {
  const borrowerPersonalInfo = {} as BorrowerPersonalInformation;

  if (mortgageApplicationContext.borrowerDateOfBirth) {
    borrowerPersonalInfo.dateOfBirth = transformDateToISO(mortgageApplicationContext.borrowerDateOfBirth);
  }

  if (mortgageApplicationContext.borrowerPhoneNumber) {
    borrowerPersonalInfo.phoneNumber = mortgageApplicationContext.borrowerPhoneNumber;
  }

  if (mortgageApplicationContext.borrowerSocialSecurityNumber) {
    borrowerPersonalInfo.ssn = mortgageApplicationContext.borrowerSocialSecurityNumber;
  }

  if (mortgageApplicationContext.borrowerEmploymentStatus) {
    borrowerPersonalInfo.employmentStatus = EMPLOYMENT_STATUS_MAP[mortgageApplicationContext.borrowerEmploymentStatus];
  }

  if (mortgageApplicationContext.borrowerEmployer) {
    borrowerPersonalInfo.employerName = mortgageApplicationContext.borrowerEmployer;
  }

  if (mortgageApplicationContext.borrowerTitle) {
    borrowerPersonalInfo.jobTitle = mortgageApplicationContext.borrowerTitle;
  }

  if (mortgageApplicationContext.borrowerYearsAtCompany) {
    borrowerPersonalInfo.yearsAtCompany = parseInt(mortgageApplicationContext.borrowerYearsAtCompany, 10);
  }

  if (mortgageApplicationContext.borrowerYearsAtAddress) {
    borrowerPersonalInfo.yearsAtAddress = parseInt(mortgageApplicationContext.borrowerYearsAtAddress, 10);
  }

  if (mortgageApplicationContext.borrowerStreetAddress) {
    borrowerPersonalInfo.address = {
      city: mortgageApplicationContext.borrowerCity,
      county: mortgageApplicationContext.borrowerCounty,
      state: STATE_NAME_TO_ABBREVIATION.get(mortgageApplicationContext.borrowerState),
      street: mortgageApplicationContext.borrowerStreetAddress,
      zipCode: mortgageApplicationContext.borrowerZipCode,
    };
  }

  if (Object.keys(borrowerPersonalInfo).length > 0) {
    return borrowerPersonalInfo;
  }
  return null;
}

const buildBorrowerFinancialInfo = (mortgageApplicationContext: MortgageApplicationStore) : FinancialInfo | null => {
  const financialInfo = {} as FinancialInfo;

  if (mortgageApplicationContext.borrowerYearlySalary) {
    financialInfo.yearlySalary = parseFloat(mortgageApplicationContext.borrowerYearlySalary);
  }

  if (mortgageApplicationContext.borrowerOtherIncomePerYear) {
    financialInfo.otherIncomePerYear = parseFloat(mortgageApplicationContext.borrowerOtherIncomePerYear);
  }

  if (mortgageApplicationContext.applicationType === 'Purchase' && mortgageApplicationContext.borrowerAmountInSavings) {
    financialInfo.liquidAssets = parseFloat(mortgageApplicationContext.borrowerAmountInSavings)
  }

  if (Object.keys(financialInfo).length > 0) {
    return financialInfo;
  }
  return null;
}

const buildBorrowerInfo = (mortgageApplicationContext: MortgageApplicationStore) : BorrowerInfo => {
  const borrowerInfo = {} as BorrowerInfo;

  const borrowerPersonalInfo = buildBorrowerPersonalInfo(mortgageApplicationContext);
  if (borrowerPersonalInfo) {
    borrowerInfo.personalInformation = borrowerPersonalInfo;
  }

  const borrowerFinancialInformation = buildBorrowerFinancialInfo(mortgageApplicationContext);
  if (borrowerFinancialInformation) {
    borrowerInfo.financialInformation = borrowerFinancialInformation;
  }

  if (mortgageApplicationContext.borrowerCreditAuthDateTime) {
    borrowerInfo.creditAuthorization = {
      authorizedAt: mortgageApplicationContext.borrowerCreditAuthDateTime,
    };
  }

  if (mortgageApplicationContext.borrowerFirstTimeHomeBuyer !== '') {
    borrowerInfo.firstTimeHomeBuyer = mortgageApplicationContext.borrowerFirstTimeHomeBuyer === 'Yes';
  }

  return borrowerInfo;
}

const buildCoBorrowerPersonalInfo = (mortgageApplicationContext: MortgageApplicationStore): CoBorrowerPersonalInformation | null => {
  const coBorrowerPersonalInfo: CoBorrowerPersonalInformation = {};

  if (mortgageApplicationContext.coBorrowerFirstName) {
    coBorrowerPersonalInfo.firstName = mortgageApplicationContext.coBorrowerFirstName;
  }

  if (mortgageApplicationContext.coBorrowerLastName) {
    coBorrowerPersonalInfo.lastName = mortgageApplicationContext.coBorrowerLastName;
  }

  if (mortgageApplicationContext.coBorrowerDateOfBirth) {
    coBorrowerPersonalInfo.dateOfBirth = transformDateToISO(mortgageApplicationContext.coBorrowerDateOfBirth);
  }

  if (mortgageApplicationContext.coBorrowerEmail) {
    coBorrowerPersonalInfo.email = mortgageApplicationContext.coBorrowerEmail;
  }

  if (mortgageApplicationContext.coBorrowerPhoneNumber) {
    coBorrowerPersonalInfo.phoneNumber = mortgageApplicationContext.coBorrowerPhoneNumber;
  }

  if (mortgageApplicationContext.coBorrowerSocialSecurityNumber) {
    coBorrowerPersonalInfo.ssn = mortgageApplicationContext.coBorrowerSocialSecurityNumber;
  }

  if (mortgageApplicationContext.coBorrowerEmploymentStatus) {
    coBorrowerPersonalInfo.employmentStatus = COBORROWER_EMPLOYMENT_STATUS_MAP[mortgageApplicationContext.coBorrowerEmploymentStatus];
  }

  if (mortgageApplicationContext.coBorrowerEmployer) {
    coBorrowerPersonalInfo.employerName = mortgageApplicationContext.coBorrowerEmployer;
  }

  if (mortgageApplicationContext.coBorrowerTitle) {
    coBorrowerPersonalInfo.jobTitle = mortgageApplicationContext.coBorrowerTitle;
  }

  if (mortgageApplicationContext.coBorrowerYearsAtCompany) {
    coBorrowerPersonalInfo.yearsAtCompany = parseInt(mortgageApplicationContext.coBorrowerYearsAtCompany, 10);
  }

  if (mortgageApplicationContext.coBorrowerStreetAddress) {
    coBorrowerPersonalInfo.address = {
      city: mortgageApplicationContext.coBorrowerCity,
      county: mortgageApplicationContext.coBorrowerCounty,
      state: STATE_NAME_TO_ABBREVIATION.get(mortgageApplicationContext.coBorrowerState),
      street: mortgageApplicationContext.coBorrowerStreetAddress,
      zipCode: mortgageApplicationContext.coBorrowerZipCode,
    };
  }

  if (Object.keys(coBorrowerPersonalInfo).length > 0) {
    return coBorrowerPersonalInfo;
  }
  return null;
}

const buildCoBorrowerFinancialInfo = (mortgageApplicationContext: MortgageApplicationStore) : FinancialInfo | null => {
  const financialInfo = {} as FinancialInfo;

  if (mortgageApplicationContext.coBorrowerYearlySalary) {
    financialInfo.yearlySalary = parseFloat(mortgageApplicationContext.coBorrowerYearlySalary);
  }

  if (mortgageApplicationContext.coBorrowerOtherIncomePerYear) {
    financialInfo.otherIncomePerYear = parseFloat(mortgageApplicationContext.coBorrowerOtherIncomePerYear);
  }

  if (mortgageApplicationContext.applicationType === 'Purchase' && mortgageApplicationContext.coBorrowerAmountInSavings) {
    financialInfo.liquidAssets = parseFloat(mortgageApplicationContext.coBorrowerAmountInSavings)
  }

  if (Object.keys(financialInfo).length > 0) {
    return financialInfo;
  }
  return null;
}

const buildCoBorrowerInfo = (mortgageApplicationContext: MortgageApplicationStore) : CoBorrowerInfo | null => {
  const coBorrowerInfo = {} as CoBorrowerInfo;

  const coBorrowerPersonalInfo = buildCoBorrowerPersonalInfo(mortgageApplicationContext);
  if (coBorrowerPersonalInfo) {
    coBorrowerInfo.personalInformation = coBorrowerPersonalInfo;
  }

  const coBorrowerFinancialInformation = buildCoBorrowerFinancialInfo(mortgageApplicationContext);
  if (coBorrowerFinancialInformation) {
    coBorrowerInfo.financialInformation = coBorrowerFinancialInformation;
  }

  if (mortgageApplicationContext.coBorrowerCreditAuthDateTime) {
    coBorrowerInfo.creditAuthorization = {
      authorizedAt: mortgageApplicationContext.coBorrowerCreditAuthDateTime,
    };
  }

  if (Object.keys(coBorrowerInfo).length > 0) {
    return coBorrowerInfo;
  }
  return null;
}

const buildRate = (mortgageApplicationContext: MortgageApplicationStore, selectedLoan?: IndividualPersonalizedLoan): Rate | null => {
  if (selectedLoan) {
    return {
      apr: selectedLoan.apr,
      discountPoints: selectedLoan.discountPoints,
      estimatedMonthlyPayment: selectedLoan.estimatedMonthlyPayment,
      estimatedPrivateMortgageInsurance: selectedLoan.estimatedPrivateMortgageInsurance,
      loanTerm: LOAN_TERM_MAP[mortgageApplicationContext.loanTerm],
      rate: selectedLoan.rate,
    }
  }
  return null;
}

export const buildGuidedExperience = (mortgageApplicationContext: MortgageApplicationStore, selectedLoan?: IndividualPersonalizedLoan): GuidedExperience => {
  const guidedExperience = {
    borrower: buildBorrowerInfo(mortgageApplicationContext),
    loan: buildLoan(mortgageApplicationContext),
  } as GuidedExperience;

  const coBorrowerInfo = buildCoBorrowerInfo(mortgageApplicationContext);
  if (coBorrowerInfo) {
    guidedExperience.coBorrower = coBorrowerInfo;
  }

  const propertyInfo = buildProperty(mortgageApplicationContext);
  if (propertyInfo) {
    guidedExperience.property = propertyInfo;
  }

  const rateInfo = buildRate(mortgageApplicationContext, selectedLoan);
  if (rateInfo) {
    guidedExperience.rate = rateInfo;
  }

  return guidedExperience;
}
