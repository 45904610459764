import { useState, useEffect } from 'react';
import todaysRates from '@lightspeed/api/services/content/todays-rates';

interface Rate {
  term: number,
  apr: number,
  discountPoints: number,
  loanAmount: number,
  rate: number,
  payment: number,
  loanType: 'refinance'|'purchase';
}

interface MortgageTerm {
  'refinance': Rate,
  'purchase': Rate,
}

export interface TodaysRates {
  'fifteen': MortgageTerm,
  'twenty': MortgageTerm,
  'thirty': MortgageTerm,
};

const termMap = new Map<Number, String>([
  [15, 'fifteen'],
  [20, 'twenty'],
  [30, 'thirty'],
]);

export default function useTodaysRates(): TodaysRates|null {
  const [rates, setRates] = useState<TodaysRates|null>(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await todaysRates();

        const networkRates = await response.json() as Rate[];

        const formattedRates = {
          'fifteen': {
            'purchase': {},
            'refinance': {},
          },
          'thirty': {
            'purchase': {},
            'refinance': {},
          },
          'twenty': {
            'purchase': {},
            'refinance': {},
          },
        };

        networkRates.forEach((r) => {
          const termKey = termMap.get(r.term) as string;

          const { loanType } = r;

          formattedRates[termKey as 'fifteen'|'twenty'|'thirty'][loanType] = r;
        });

        setRates(formattedRates as TodaysRates);
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert('There was an issue loading the page. Please refresh.');
      }
    })();
  }, []);

  return rates;
}
