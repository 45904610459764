import React, { useState } from 'react';
import styled from 'styled-components';
import { Heading, Button, OptionalElement, Label } from '@lightspeed/components/ui/atoms';
import { CardSelect } from '@lightspeed/components/ui/organisms';
import { Page } from '@lightspeed/components/ui/templates/base-page-template/page';
import { SplitSection } from '@lightspeed/components/ui/templates/split-section';
import { TermSelect } from '@lightspeed/components/shared/terms-select/terms-select';
import { Header } from '@lightspeed/components/ui/molecules/header';
import { BasePageTemplate } from '@lightspeed/components/ui/templates';
import { MortgageApplicationStore, MortgageApplicationStoreKeys } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { useValidation } from '@lightspeed/hooks/useValidation/useValidation';
import { getButtonDisabledState } from '@lightspeed/utils/get-button-disabled-state';
import { useMortgageApplication } from '@lightspeed/contexts/mortgage-application-context/mortgage-application-context';
import { useNextRoute } from '@lightspeed/routing/useNextRoute';
import { AddressForm } from '@lightspeed/components/ui/pages/v2/current-address-purchase/address-form';
import { TodaysRates } from '@lightspeed/components/ui/pages/v2/get-started/basic-info/todays-rates';
import { HelocSidebarBlurb } from '@lightspeed/components/shared/heloc-sidebar-blurb/heloc-sidebar-blurb';
import { RateDisclaimer } from '@lightspeed/components/shared/legal-disclaimers/rate-disclaimer';
import { PurchaseRefinanceSidebarBlurb } from '@lightspeed/components/shared/purchase-refinance-sidebar-blurb/purchase-refinance-sidebar-blurb';
import { useMortgageContextDefault } from '@lightspeed/hooks/useMortgageContextDefaults/useMortgageContextDefaults';
import { useApplicationLead } from '@lightspeed/hooks/useApplicationLead/useApplicationLead';
import { LoadingModal } from '@lightspeed/components/ui/molecules';
import { useAnalytics } from '@lightspeed/hooks/useAnalytics/useAnalytics';
import {
  mortgageApplicationSchema,
} from '@lightspeed/contexts/mortgage-application-context/mortage-application-validation';

const setPropertyAddressToCurrentAddress = (mortgageApplication: MortgageApplicationStore, updateMortgageApplication: (key: keyof MortgageApplicationStore, value: string) => void) => {
  updateMortgageApplication('propertyStreetAddress', mortgageApplication.borrowerStreetAddress);
  updateMortgageApplication('propertyCity', mortgageApplication.borrowerCity);
  updateMortgageApplication('propertyState', mortgageApplication.borrowerState);
  updateMortgageApplication('propertyCounty', mortgageApplication.borrowerCounty);
  updateMortgageApplication('propertyZipCode', mortgageApplication.borrowerZipCode);
};

const borrowerKeys: MortgageApplicationStoreKeys[] = [
  'borrowerStreetAddress',
  'borrowerCity',
  'borrowerZipCode',
  'borrowerState',
  'borrowerYearsAtAddress',
];

const coBorrowerKeys: MortgageApplicationStoreKeys[] = [
  'coBorrowerStreetAddress',
  'coBorrowerCity',
  'coBorrowerZipCode',
  'coBorrowerState',
];

export function CurrentAddressNonPurchase() {
  const { mortgageApplication, updateMortgageApplication } = useMortgageApplication();
  const hasCoBorrower = !!mortgageApplication.coBorrowerFirstName;
  const [coBorrowerHasSameAddress, setCoBorrowerHasSameAddress] = useState<boolean>(true);
  const goToNextRoute = useNextRoute(mortgageApplication);
  const fireAnalyticsEvent = useAnalytics('v2/current_address_non_purchase');
  const { upsertLead } = useApplicationLead();
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  useMortgageContextDefault('reasonForRefinance');

  const [validate, errors] = useValidation(mortgageApplicationSchema, {
    keysToValidate: hasCoBorrower ? [...borrowerKeys, ...coBorrowerKeys] : borrowerKeys,
    yupValidationContext: {
      hasCoBorrower,
    },
  });

  const disableNextButton = getButtonDisabledState<MortgageApplicationStore>(
    mortgageApplication,
    hasCoBorrower ?
      coBorrowerHasSameAddress ? borrowerKeys : [...borrowerKeys, ...coBorrowerKeys]
      : borrowerKeys,
  );

  const onNextClick = () => {
    (async () => {
      if (hasCoBorrower && coBorrowerHasSameAddress) {
        updateMortgageApplication('coBorrowerStreetAddress', mortgageApplication.borrowerStreetAddress);
        updateMortgageApplication('coBorrowerCity', mortgageApplication.borrowerCity);
        updateMortgageApplication('coBorrowerCounty', mortgageApplication.borrowerCounty);
        updateMortgageApplication('coBorrowerZipCode', mortgageApplication.borrowerZipCode);
        updateMortgageApplication('coBorrowerState', mortgageApplication.borrowerState);
      }

      const validationResult = validate(mortgageApplication);

      if (validationResult.success) {
        if (mortgageApplication.propertyStreetAddress === '') {
          setPropertyAddressToCurrentAddress(mortgageApplication, updateMortgageApplication);
        }

        setShowLoadingModal(true);
        try {
          await upsertLead();
          setShowLoadingModal(false);
        } catch (e) {
          // do nothing
        }

        fireAnalyticsEvent('next_button_success');
        goToNextRoute();
      }
    })();
  };

  return (
    <>
      <OptionalElement show={showLoadingModal}>
        <LoadingModal />
      </OptionalElement>
      <Page
        desktop={(
          <>
            <Header />
            <BasePageTemplate>
              <SplitSection>
                <SplitSection.Content>
                  <Heading element={'h1'}>
                    The basics.
                  </Heading>
                  <OptionalElement show={mortgageApplication.applicationType !== 'Heloc'}>
                    <LookingToDoWrapper>
                      <Heading
                        element={'h2'}
                        marginBottom={'1em'}
                        marginTop={'1em'}
                      >
                        What are you looking to do?
                      </Heading>
                      <CardSelect
                        onChange={(v) => {
                          updateMortgageApplication('reasonForRefinance', v);
                        }}
                        value={mortgageApplication.reasonForRefinance}
                        options={[
                          {
                            label: 'I want a lower monthly payment',
                            tag: {
                              text: 'Lowest Payment',
                              variant: 'purple',
                            },
                            value: 'lowerPayment',
                          },
                          {
                            label: 'I want a lower interest rate.',
                            tag: {
                              text: 'Lowest Rate',
                              variant: 'blue',
                            },
                            value: 'lessInterest',
                          },
                          {
                            label: 'I want to access the equity in my home.',
                            tag: {
                              text: 'Cash-out',
                              variant: 'green',
                            },
                            value: 'cashOut',
                          },
                        ]}
                      />
                    </LookingToDoWrapper>
                  </OptionalElement>
                  <AddressForm
                    fireAnalyticsEvent={fireAnalyticsEvent}
                    mortgageApplication={mortgageApplication}
                    hasCoBorrower={hasCoBorrower}
                    updateMortgageApplication={updateMortgageApplication}
                    errors={errors}
                    coBorrowerHasSameAddress={coBorrowerHasSameAddress}
                    setCoBorrowerHasSameAddress={setCoBorrowerHasSameAddress}
                  />
                </SplitSection.Content>
                <SplitSection.Sidebar>
                  <OptionalElement show={mortgageApplication.applicationType !== 'Heloc'}>
                    <TodaysRates
                      mortgageApplication={mortgageApplication}
                    />
                    <Label marginBottom={'8px'}>
                      Loan Term
                    </Label>
                    <TermSelect
                      value={mortgageApplication.loanTerm}
                      onChange={(v) => {
                        updateMortgageApplication('loanTerm', v);
                      }}
                    />
                  </OptionalElement>
                  <OptionalElement show={mortgageApplication.applicationType !== 'Heloc'}>
                    <PurchaseRefinanceSidebarBlurb />
                  </OptionalElement>
                  <OptionalElement show={mortgageApplication.applicationType === 'Heloc'}>
                    <HelocSidebarBlurb />
                  </OptionalElement>
                  <Button
                    marginY={'32px'}
                    disabled={disableNextButton}
                    onClick={onNextClick}
                  >
                    Next
                  </Button>
                  <RateDisclaimer />
                </SplitSection.Sidebar>
              </SplitSection>
            </BasePageTemplate>
          </>
        )}
        mobile={(
          <>
            <Header />
            <BasePageTemplate>
              <Heading element={'h1'}>
                The basics.
              </Heading>
              <OptionalElement show={mortgageApplication.applicationType !== 'Heloc'}>
                <Heading
                  element={'h2'}
                  marginBottom={'1em'}
                  marginTop={'1em'}
                >
                  What are you looking to do?
                </Heading>
                <LookingToDoWrapper>
                  <MobileLookingToDoSlider>
                    <CardSelect
                      onChange={(v) => {
                        updateMortgageApplication('reasonForRefinance', v);
                      }}
                      value={mortgageApplication.reasonForRefinance}
                      options={[
                        {
                          label: 'I want a lower monthly payment',
                          tag: {
                            text: 'Lowest Payment',
                            variant: 'purple',
                          },
                          value: 'lowerPayment',
                        },
                        {
                          label: 'I want a lower interest rate.',
                          tag: {
                            text: 'Lowest Rate',
                            variant: 'blue',
                          },
                          value: 'lessInterest',
                        },
                        {
                          label: 'I want to access the equity in my home.',
                          tag: {
                            text: 'Cash-out',
                            variant: 'green',
                          },
                          value: 'cashOut',
                        },
                      ]}
                    />
                  </MobileLookingToDoSlider>
                </LookingToDoWrapper>
              </OptionalElement>
              <AddressForm
                fireAnalyticsEvent={fireAnalyticsEvent}
                mortgageApplication={mortgageApplication}
                hasCoBorrower={hasCoBorrower}
                updateMortgageApplication={updateMortgageApplication}
                errors={errors}
                coBorrowerHasSameAddress={coBorrowerHasSameAddress}
                setCoBorrowerHasSameAddress={setCoBorrowerHasSameAddress}
              />
              <Button
                marginTop={'32px'}
                marginBottom={'32px'}
                disabled={disableNextButton}
                onClick={onNextClick}
              >
                Next
              </Button>
            </BasePageTemplate>
          </>
        )}
      />
    </>
  );
}

const LookingToDoWrapper = styled.div`
  margin-bottom: 48px;
  overflow-x: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

const MobileLookingToDoSlider = styled.div`
  width: 120vw;
  overflow: visible;
  padding-top: 12px;
`;
