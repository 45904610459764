import { createContext, useContext, ReactNode } from 'react';

export interface RQWRates {
  rateFor15YearTerm: number;
  rateFor20YearTerm: number;
  rateFor30YearTerm: number;
  aprFor15YearTerm: number;
  aprFor20YearTerm: number;
  aprFor30YearTerm: number;
  discountPointsFor15YearTerm: number;
  discountPointsFor20YearTerm: number;
  discountPointsFor30YearTerm: number;
  monthlyPaymentFor15YearTerm: number;
  monthlyPaymentFor20YearTerm: number;
  monthlyPaymentFor30YearTerm: number;
  loanAmount: number;
  reasonForRefinance: string;
  ltv: number;
}

const RQWRatesContext = createContext<null|RQWRates>(null);

export function RQWRatesContextProvider({ children, overrides }: { children: ReactNode|ReactNode[], overrides: RQWRates|null }) {
  return (
    <RQWRatesContext.Provider value={overrides}>
      {children}
    </RQWRatesContext.Provider>
  );
}

export function useRQWRates() {
  return useContext(RQWRatesContext);
}
