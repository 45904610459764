import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Gotham-Bold';
    src: url('/assets/fonts/Gotham_Bold.eot?') format('eot'),
    url('/assets/fonts/Gotham_Bold.woff2') format('woff2'),
    url('/assets/fonts/Gotham_Bold.woff') format('woff'),
    url('/assets/fonts/Gotham_Bold.otf') format('opentype'),
    url('/assets/fonts/Gotham_Bold.ttf') format('truetype'),
    url('/assets/fonts/Gotham_Bold.svg#Gotham_Bold') format('svg');
  }

  @font-face {
    font-family: 'Gotham-Book';
    src: url('/assets/fonts/Gotham_Book.eot?') format('eot'),
    url('/assets/fonts/Gotham_Book.woff2') format('woff2'),
    url('/assets/fonts/Gotham_Book.woff') format('woff'),
    url('/assets/fonts/Gotham_Book.otf') format('opentype'),
    url('/assets/fonts/Gotham_Book.ttf') format('truetype'),
    url('/assets/fonts/Gotham_Book.svg#Gotham_Book') format('svg');
  }

  @font-face {
    font-family: 'Gotham-Light';
    src: url('/assets/fonts/Gotham_Light.eot?') format('eot'),
    url('/assets/fonts/Gotham_Light.woff2') format('woff2'),
    url('/assets/fonts/Gotham_Light.woff') format('woff'),
    url('/assets/fonts/Gotham_Light.otf') format('opentype'),
    url('/assets/fonts/Gotham_Light.ttf') format('truetype'),
    url('/assets/fonts/Gotham_Light.svg#Gotham_Light') format('svg');
  }

  html,
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: normal normal 16px/1 'Gotham-Book', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    // Disable Chrome Android swipe refresh
    overscroll-behavior: contain;
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box;
    background-repeat: no-repeat;
  }

  *:focus {
    outline: none;
  }

  * h1,
  * h2,
  * h3,
  * h4,
  * h5,
  * h6 {
    margin: 0;
  }

  a,
  area,
  button,
  input,
  label,
  select,
  textarea,
  [tabindex] {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

`;
