import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import useAddressAutocomplete from '@lightspeed/hooks/useAddressAutocomplete/useAddressAutocomplete';
import { TextInput, TextInputProps } from '@lightspeed/components/ui/atoms/text-input/text-input';
import { getAddressByPlaceId } from '@lightspeed/api/services/google-maps/autocomplete';
import useModal from '@lightspeed/hooks/useModal';
import { OptionalElement, Label, Paragraph } from '@lightspeed/components/ui/atoms';
import useOnClickOutside from '@lightspeed/hooks/useOnClickOutside';
import { theme } from '@lightspeed/styles/primary/theme';
import { FormRow } from '@lightspeed/components/ui/templates/form/form';
import { LottieSource } from '@lightspeed/components/ui/atoms/lottie-source';
import { FormattedGoogleAddress } from '@lightspeed/models/google-maps';
import { getFormattedAddressFromPlaceResult } from '@lightspeed/utils/google-maps';
import BluePlus from './blue-plus.svg';
import BlueCaret from './blue-caret.svg';
import GreyX from './grey-x.svg';
import PoweredByGoogle from './powered_by_google_on_white.png';
import LoadingSpinner from './loading-spinner.json';

export function AddressAutocompleteWrapper({ children, id }: { children: React.ReactNode; id: string; }) {
  return (
    <FormRow>
      <AutoCompleteLabelWrapper>
        <Label htmlFor={id}>
          Address*
        </Label>
        <AutoCompleteInputWrapper>
          {children}
        </AutoCompleteInputWrapper>
      </AutoCompleteLabelWrapper>
    </FormRow>
  );
}

export function formatFeatureToString(predictions: FormattedGoogleAddress) {
  if (predictions.streetNumber && predictions.streetName && predictions.city && predictions.stateAbbreviation && predictions.zipcode) {
    return `${predictions.streetNumber} ${predictions.streetName} ${predictions.city}, ${predictions.stateAbbreviation} ${predictions.zipcode}`;
  }
}

export type AddressAutocompleteOnChange = (result: FormattedGoogleAddress|null) => void;

interface AddressAutocompleteProps {
  fallbackUi: React.ReactNode;
  id: string;
  initialSearchText: string;
  initialShowFallback: boolean;
  onChange: AddressAutocompleteOnChange;
  placeholder: string;
  WrappingElement: React.ComponentType<{ children: React.ReactNode; id: string; }>;
  fireAnalyticsEvent: (event: string) => void;
}

export function AddressAutocomplete({
  placeholder,
  onChange,
  fallbackUi,
  initialSearchText,
  initialShowFallback,
  WrappingElement,
  id,
  fireAnalyticsEvent,
}: AddressAutocompleteProps) {
  const [searchText, setSearchText] = useState<string>(initialSearchText);
  const [loading, results] = useAddressAutocomplete(searchText, 200);
  const [shouldShow, show, hide] = useModal();
  const [showFallback, setShowFallback] = useState(initialShowFallback);
  const searchWrapperRef = useRef<HTMLDivElement|null>(null);
  const searchInputRef = useRef<HTMLInputElement|null>(null);
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints[0]})`,
  });

  const shouldDisplayInModal = shouldShow && !isDesktop;

  // this is used on desktop to detect clicks outside the wrapper element
  useOnClickOutside(searchWrapperRef, () => {
    hide();
  });

  return (
    <>
      <OptionalElement show={!showFallback}>
        <WrappingElement id={id}>
          <SearchWrapper
            ref={searchWrapperRef}
            $modal={shouldDisplayInModal}
          >
            <SearchTextInputWrapper>
              <IconButton
                onClick={hide}
              >
                <BlueCaretIcon src={BlueCaret} />
              </IconButton>
              <SearchTextInput
                autoComplete={'off'}
                id={id}
                placeholder={placeholder}
                ref={searchInputRef}
                $isDisplayingInModal={shouldDisplayInModal}
                value={searchText}
                onChange={(e) => {
                  fireAnalyticsEvent('searched_for_address');
                  setSearchText(e.target.value);
                  onChange(null);
                }}
                onFocus={show}
              />
              <IconButton
                onClick={() => {
                  setSearchText('');
                  searchInputRef.current?.focus();
                }}
              >
                <BlueCaretIcon src={GreyX} />
              </IconButton>
            </SearchTextInputWrapper>
            <OptionalElement show={shouldShow}>
              <ResultsWrapper>
                <OptionalElement show={loading}>
                  <SpinnerWrapper>
                    <LottieSource
                      lottieJSONFile={LoadingSpinner}
                      style={{
                        height: '22px',
                        width: '22px',
                      }}
                    />
                    <Paragraph variant={'light'}>
                      Searching for your address
                    </Paragraph>
                  </SpinnerWrapper>
                </OptionalElement>
                <OptionalElement show={!loading}>
                  {results.map((r) => (
                    <PropertyButton
                      onClick={() => {
                        (async () => {
                          fireAnalyticsEvent('selected_autocomplete_result');
                          setSearchText(r.description);
                          if (r.place_id) {
                            await getAddressByPlaceId(r.place_id, (result) => {
                              if (!result) {
                                return;
                              }
                              const address = getFormattedAddressFromPlaceResult(result);
                              setSearchText(formatFeatureToString(address) ?? '');
                              onChange(address);
                              hide();
                            });
                          }
                        })();
                      }}
                      key={`${r.description}`}
                    >
                      {r.description}
                    </PropertyButton>
                  ))}
                </OptionalElement>
                <OptionalElement show={!isDesktop || (isDesktop && !loading)}>
                  <AddManuallyButton
                    onClick={() => {
                      fireAnalyticsEvent('manual_address_entry_clicked');
                      setShowFallback(true);
                      onChange(null);
                    }}
                  >
                    <BluePlusIcon
                      src={BluePlus}
                      alt={'plus'}
                    /> Add Address Manually
                  </AddManuallyButton>
                </OptionalElement>
                <OptionalElement show={results.length > 0 && !loading}>
                  <AttributionImageWrapper>
                    <img
                      src={PoweredByGoogle}
                      alt={'Powered by Google'}
                    />
                  </AttributionImageWrapper>
                </OptionalElement>
              </ResultsWrapper>
            </OptionalElement>
          </SearchWrapper>
        </WrappingElement>
      </OptionalElement>
      <OptionalElement show={showFallback}>
        {fallbackUi}
      </OptionalElement>
    </>
  );
}

interface SearchTextInputProps extends TextInputProps {
  $isDisplayingInModal: boolean;
}

const SearchTextInputWrapper = styled.div`
  display: flex;
`;

const SearchTextInput = styled(TextInput)<SearchTextInputProps>`
  ${(props) => props.$isDisplayingInModal && css`
    border: none;
  `}
`;

const IconButton = styled.button`
  display: none;
  all: unset;
`;

const BlueCaretIcon = styled.img`
  display: none;
`;

const BluePlusIcon = styled.img`
  padding-right: 12px;
`;

const PropertyButton = styled.button`
  all: unset;
  font-size: 16px;
  line-height: 24px;
  color: #6A6B76;
  padding: 12px 0;
  cursor: pointer;
  ${(props) => props.theme.media.desktop} {
    padding: 12px;
    line-height: 16px;
  }
`;

const AddManuallyButton = styled.button`
  all: unset;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  color: #6A6B76;
  padding: 12px 0;
  margin-top: auto;
  ${(props) => props.theme.media.desktop} {
    padding: 12px;
  }
`;

interface SearchWrapperProps {
  $modal: boolean;
}

const SearchWrapper = styled.div<SearchWrapperProps>`
  position: relative;

  ${(props) => props.$modal && css`
    background-color: #fff;
    position: fixed;
    width: 100vw;
    min-height: 100%;
    min-height: -webkit-fill-available;
    top: 0;
    left: 0;
    padding: 24px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    & ${IconButton} {
      display: block;
    }

    & ${BlueCaretIcon} {
      display: block;
    }

    & ${IconButton}:first-of-type {
      padding-right: 16px;
    }

    & ${IconButton}:last-of-type {
      padding-left: 16px;
    }
  `}
`;

const ResultsWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background: #fff;
  width: 100%;
  padding: 16px 0;

  ${(props) => props.theme.media.desktop} {
    flex-grow: 0;
    position: absolute;
    top: calc(100% + 12px);
    z-index: 1000;

    border: 1px solid #F4F4F8;
    border-radius: 12px;

    padding: 16px;
  }
`;
const AutoCompleteLabelWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

const AutoCompleteInputWrapper = styled.div`
  flex: 1;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;

  ${(props) => props.theme.media.desktop} {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const AttributionImageWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
`;
