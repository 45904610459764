import { Error } from '@lightspeed/components/ui/atoms/error';

interface ValidationErrorProps {
  path: string;
  errors: Map<string, string[]> | null;
}

export const ValidationError = ({ path, errors }: ValidationErrorProps) => {
  if (!errors || !errors.get(path)) {
    return null;
  }
  const errorMessage = (errors.get(path) as string[])[0];

  return (
    <Error>
      {errorMessage}
    </Error>
  );
}
