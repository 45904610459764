import URI from 'urijs';
import { STATE_ABBREVIATION_TO_NAME } from '@lightspeed/models/us-states';

export interface RQWParams {
  fromRateQuery?: string;
  loanTerm?: 'fifteen'|'twenty'|'thirty';
  propertyType?: string;
  propertyResidenceType?: string;
  propertyState?: string;
  propertyZipCode?: string;
  rateFor15YearTerm?: number;
  rateFor20YearTerm?: number;
  rateFor30YearTerm?: number;
  aprFor15YearTerm?: number;
  aprFor20YearTerm?: number;
  aprFor30YearTerm?: number;
  discountPointsFor15YearTerm?: number;
  discountPointsFor20YearTerm?: number;
  discountPointsFor30YearTerm?: number;
  monthlyPaymentFor15YearTerm?: number;
  monthlyPaymentFor20YearTerm?: number;
  monthlyPaymentFor30YearTerm?: number;
  propertyDownPayment?: string;
  propertyPurchasePrice?: string;
  propertyCashOut?: string;
  propertyHomeValue?: string;
  propertyMortgageBalance?: string;
  loanAmount?: number;
  reasonForRefinance?: 'lowerPayment' | 'cashOut' | 'lessInterest' | '';
  ltv?: number;
}

const refinanceMap: Record<string, '' | 'cashOut' | 'lowerPayment' | 'lessInterest'> = {
  '': '',
  'cashOut': 'cashOut',
  'lessInterest': 'lessInterest',
  'lowerPayment': 'lowerPayment',
};

const termMap: Record<string, 'fifteen'|'twenty'|'thirty'> = {
  '15': 'fifteen',
  '20': 'twenty',
  '30': 'thirty',
};

const usageMap: Record<'PrimaryResidence'|'SecondHome'|'InvestmentProperty', 'Primary Residence'|'Secondary Residence'|'Investment'> = {
  'InvestmentProperty': 'Investment',
  'PrimaryResidence': 'Primary Residence',
  'SecondHome': 'Secondary Residence',
};

const propertyTypeMap: Record<'SingleFamily'|'Condo'|'Duplex'|'Triplex'|'Fourplex', string> = {
  'Condo': 'Condo',
  'Duplex': 'Duplex',
  'Fourplex': 'Fourplex',
  'SingleFamily': 'Single Family',
  'Triplex': 'Triplex',
};

const transformers: Record<string, (v: string) => Partial<RQWParams>> = {
  aprFor15YearTerm: (v: string): Partial<RQWParams> => ({
    aprFor15YearTerm: Number(v),
  }),
  aprFor20YearTerm: (v: string): Partial<RQWParams> => ({
    aprFor20YearTerm: Number(v),
  }),
  aprFor30YearTerm: (v: string): Partial<RQWParams> => ({
    aprFor30YearTerm: Number(v),
  }),
  cashOut: (v: string): Partial<RQWParams> => ({
    propertyCashOut: v,
  }),
  discountPointsFor15YearTerm: (v: string): Partial<RQWParams> => ({
    discountPointsFor15YearTerm: Number(v),
  }),
  discountPointsFor20YearTerm: (v: string): Partial<RQWParams> => ({
    discountPointsFor20YearTerm: Number(v),
  }),
  discountPointsFor30YearTerm: (v: string): Partial<RQWParams> => ({
    discountPointsFor30YearTerm: Number(v),
  }),
  downPayment: (v: string): Partial<RQWParams> => ({
    propertyDownPayment: v,
  }),
  fromRateQuery: (v: string): Partial<RQWParams> => ({
    fromRateQuery: v,
  }),
  homeValue: (v: string): Partial<RQWParams> => ({
    propertyHomeValue: v,
  }),
  loanAmount: (v: string): Partial<RQWParams> => ({
    loanAmount: Number(v),
  }),
  loanTerm: (v: string): Partial<RQWParams> => ({
    loanTerm: termMap[v],
  }),
  ltv: (v: string): Partial<RQWParams> => ({
    ltv: Number(v),
  }),
  monthlyPaymentFor15YearTerm: (v: string): Partial<RQWParams> => ({
    monthlyPaymentFor15YearTerm: Number(v),
  }),
  monthlyPaymentFor20YearTerm: (v: string): Partial<RQWParams> => ({
    monthlyPaymentFor20YearTerm: Number(v),
  }),
  monthlyPaymentFor30YearTerm: (v: string): Partial<RQWParams> => ({
    monthlyPaymentFor30YearTerm: Number(v),
  }),
  mortgageBalance: (v: string): Partial<RQWParams> => ({
    propertyMortgageBalance: v,
  }),
  propertyType: (v: string): Partial<RQWParams> => ({
    propertyType: propertyTypeMap[v as 'SingleFamily'|'Condo'|'Duplex'|'Triplex'|'Fourplex'] as string,
  }),
  propertyUse: (v: string): Partial<RQWParams> => ({
    propertyResidenceType: usageMap[v as 'PrimaryResidence'|'SecondHome'|'InvestmentProperty'] as string,
  }),
  purchasePrice: (v: string): Partial<RQWParams> => ({
    propertyPurchasePrice: v,
  }),
  rateFor15YearTerm: (v: string): Partial<RQWParams> => ({
    rateFor15YearTerm: Number(v),
  }),
  rateFor20YearTerm: (v: string): Partial<RQWParams> => ({
    rateFor20YearTerm: Number(v),
  }),
  rateFor30YearTerm: (v: string): Partial<RQWParams> => ({
    rateFor30YearTerm: Number(v),
  }),
  reasonForRefinance: (v: string): Partial<RQWParams> => ({
    reasonForRefinance: refinanceMap[v],
  }),
  state: (v: string): Partial<RQWParams> => ({
    propertyState: STATE_ABBREVIATION_TO_NAME.get(v),  // enum check
  }),
  zipcode: (v: string): Partial<RQWParams> => ({
    propertyZipCode: v,
  }),
};

export function getRateQueryWidgetParams(url: string): RQWParams {
  const paramsFromUrl = (new URI(url)).query(true) as Record<string, string>;

  const params: RQWParams = {};

  Object.keys(paramsFromUrl).forEach((key) => {
    const value = paramsFromUrl[key];
    const transformerFunction = transformers[key];

    if (transformerFunction) {
      Object.assign(params, transformerFunction(value));
    }
  });

  return params;
}
