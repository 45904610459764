import { datadogRum } from '@datadog/browser-rum';
import isbot from 'isbot';

if (!('navigator' in window) || !isbot(window.navigator.userAgent)) {
  datadogRum.init({
    allowedTracingOrigins: [process.env.API_HOST || ''],
    applicationId: process.env.DATADOG_APPLICATION_ID || '',
    clientToken: process.env.DATADOG_CLIENT_TOKEN || '',
    defaultPrivacyLevel: 'mask-user-input',
    env: process.env.DATADOG_ENV,
    sampleRate: parseInt(process.env.DATADOG_SAMPLE_RATE || '0', 10),
    service: 'lightspeed',
    site: 'us3.datadoghq.com',
    trackInteractions: true,
    version: '1.0.0',
  });

  datadogRum.startSessionReplayRecording();

  window.gtag('set', {
    dimension1: datadogRum.getInternalContext()?.session_id,
  });
}
