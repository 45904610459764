import {
  coborrowerEmploymentStatusLeadApiValues,
  employmentStatusLeadApiValues,
} from '@lightspeed/models/employment-status';

export interface Attribution {
  gclid?: string;
  utmContent?: string;
  utmCampaign?: string;
  utmMedium?: string;
  utmSource?: string;
  utmTerm?: string;
  lastReferrer?: string;
  loanOfficerToken?: string;
  isFromRateQuery?: boolean;
}

export interface Application {
  type: string;
  firstName: string;
  lastName: string;
  email: string;
  isComplete: boolean;
}

export interface CompleteAddress {
  street?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  county?: string;
}

export interface PropertyAddress {
  street?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  county?: string;
}

export interface Property {
  contractStatus?: 'InContract'|'PlacedOffer'|'StillLooking';
  occupancy?: 'PrimaryResidence'|'SecondHome'|'Investment';
  propertyType?: 'SingleFamily'|'Condo'|'Duplex'|'Triplex'|'Fourplex';
  value?: number;
  address?: PropertyAddress;
}

export interface Loan {
  purpose: 'Purchase'|'Refinance'|'Heloc';
  amount?: number;
  cashOutAmount?: number;
  isDownPaymentPartiallyGift?: boolean;
}

export interface Rate {
  loanTerm: 'Fifteen'|'Twenty'|'Thirty';
  discountPoints: number;
  estimatedPrivateMortgageInsurance: number;
  estimatedMonthlyPayment: number;
  apr: number;
  rate: number;
}

export interface FinancialInfo {
  yearlySalary?: number;
  otherIncomePerYear?: number;
  liquidAssets?: number;
}

export interface CreditAuth {
  authorizedAt?: string; // ISO 8601 date string
}

export interface BorrowerPersonalInformation {
  dateOfBirth?: string; // ISO 8601 date string
  phoneNumber?: string;
  ssn?: string;
  employmentStatus?: typeof employmentStatusLeadApiValues[number];
  employerName?: string;
  jobTitle?: string;
  yearsAtCompany?: number;
  yearsAtAddress?: number;
  address?: CompleteAddress;
}

export interface CoBorrowerPersonalInformation {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string; // ISO 8601 Date String
  email?: string;
  phoneNumber?: string;
  ssn?: string;
  employmentStatus?: typeof coborrowerEmploymentStatusLeadApiValues[number];
  employerName?: string;
  jobTitle?: string;
  yearsAtCompany?: number;
  yearsAtAddress?: number;
  address?: CompleteAddress;
}

export interface BorrowerInfo {
  personalInformation?: BorrowerPersonalInformation;
  financialInformation?: FinancialInfo;
  creditAuthorization?: CreditAuth;
  firstTimeHomeBuyer?: boolean;
}

export interface CoBorrowerInfo {
  personalInformation?: CoBorrowerPersonalInformation;
  financialInformation?: FinancialInfo;
  creditAuthorization?: CreditAuth;
}

export type ApplicationType = 'Refinance'|'Purchase'|'Heloc';

export interface GuidedExperience {
  loan: Loan;
  property?: Property;
  borrower: BorrowerInfo;
  coBorrower?: CoBorrowerInfo;
  rate?: Rate;
}

export interface CreateApplicationLeadPayload {
  application: Application;
  attribution: Attribution;
  guidedExperience: GuidedExperience;
}

export default function submitGuidedExperienceLead(payload: CreateApplicationLeadPayload) {
  return fetch(`${process.env.API_HOST}/api/v1/Lead/ApplicationLead`, {
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
}
