import * as yup from 'yup';
import { useState } from 'react';

export type ValidationResultSuccess = {
  success: true;
  errors?: never;
}

export type ValidationResultError = {
  success: false;
  errors: Map<string, string[]>
}

export type ValidationResult = ValidationResultSuccess|ValidationResultError;

interface ValidationOptions {
  keysToValidate?: string[];
  yupValidationContext?: {hasCoBorrower: boolean, hasDifferentCoBorrowerAddress?: boolean} // variables which can be accessed via .when in schema: https://github.com/jquense/yup#mixedwhenkeys-string--arraystring-builder-object--value-schema-schema-schema
};

export function useValidation(schema: yup.ObjectSchema<any>, options: ValidationOptions = {}): [(obj: object) => ValidationResult, Map<string, string[]>|null] {
  const [errors, setErrors] = useState<Map<string, string[]>|null>(null);

  const validate = (objectToValidate: object): ValidationResult => {
    try {
      const validationSchema = options.keysToValidate ? schema.pick(options.keysToValidate) : schema;

      validationSchema.validateSync(
        objectToValidate,
        {
          abortEarly: false,
          context: options.yupValidationContext,
        },
      );

      setErrors(null);

      return {
        success: true,
      };
    } catch (e) {
      const validationError = e as yup.ValidationError;

      // Path should always be set
      const errorMap = new Map(validationError.inner.map((err) => [err.params?.path as string, err.errors]));

      setErrors(errorMap);

      return {
        errors: errorMap,
        success: false,
      };
    }
  };

  return [validate, errors];
}
