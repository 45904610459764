import styled from 'styled-components';

export interface CircleContainerProps {
  background: string;
}

export interface IconCircleProps extends CircleContainerProps {
  icon: string;
  testId?: string;
}

export const IconCircle = ({
  icon, background, testId = 'icon-circle',
}: IconCircleProps) => (
  <CircleContainer
    background={background}
    data-testid={testId}
  >
    <IconImg src={icon} />
  </CircleContainer>
);

export const IconImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const CircleContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${(props: CircleContainerProps) => props.background};
  position: relative;
`;
