import { ComponentProps } from 'react';
import styled from 'styled-components';
import {
  ColorProps,
  LayoutProps,
  SpaceProps,
  color,
  compose,
  layout,
  space,
  flexbox,
  FlexboxProps,
  fontSize,
  FontSizeProps,
} from 'styled-system';

type BaseLabelProps = {
  bold?: boolean;
  disabled?: boolean;
}

export const Label = styled.label<BaseLabelProps & FontSizeProps & ColorProps & LayoutProps & SpaceProps & FlexboxProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${ (props) => props.theme.Label[props.bold ? 'bold' : 'book'] };
  color: ${ (props) => props.theme.colors[props.disabled ? 'disabledText' : 'ash'] };
  ${ compose(color, layout, space, flexbox, fontSize) };
`;

export type LabelProps = ComponentProps<typeof Label>

