import React, { ReactNode, ReactElement } from 'react';

interface OptionalElementProps {
  show: Boolean;
  children: ReactNode | ReactNode[]
}

export function OptionalElement({ show, children }: OptionalElementProps): ReactElement<OptionalElementProps> {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!show) { return <></>; }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
