import { MortgageApplicationStore } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { CONTRACT_STATUSES } from '@lightspeed/models/contract-statuses';

export const MORTGAGE_APPLICATION_DEFAULTS: Partial<MortgageApplicationStore> = {
  borrowerAmountInMutualFunds: '0',
  borrowerContractStatus: CONTRACT_STATUSES.homeShopping,
  borrowerEmploymentStatus: 'W-2',
  borrowerFirstTimeHomeBuyer: 'No',
  borrowerOtherIncomePerYear: '0',
  coBorrowerAmountInMutualFunds: '0',
  coBorrowerAmountInSavings: '0',
  coBorrowerEmploymentStatus: 'W-2',
  coBorrowerOtherIncomePerYear: '0',
  propertyCashOut: '0',
  propertyDownPaymentPartiallyGift: 'No',
  reasonForRefinance: 'lowerPayment',
};
