import { Heading, OptionalElement, Label, CurrencyInput, Symbol } from '@lightspeed/components/ui/atoms';
import { ValidationError } from '@lightspeed/components/ui/molecules/validation-error/validation-error';
import { Form, FormRow } from '@lightspeed/components/ui/templates/form/form';
import { MortgageApplicationStore } from '@lightspeed/contexts/mortgage-application-context/use-mortgage-application-state';
import { ToolTip } from '@lightspeed/components/ui/molecules';

interface YourAssetsFormProps {
  mortgageApplication: MortgageApplicationStore;
  updateMortgageApplication: (v: keyof MortgageApplicationStore, z: string) => void;
  hasCoborrower: boolean;
  errors: Map<string, string[]>|null;
};

const MAX_AMOUNT = 25000000;

function capToMaxAmount(v: string) {
  const value = parseInt(v, 10);

  if (Number.isNaN(value)) {
    return '';
  }

  return Math.min(MAX_AMOUNT, value).toString();
}

export function YourAssetsForm({
  mortgageApplication, updateMortgageApplication, hasCoborrower, errors,
}: YourAssetsFormProps) {
  return (
    <>
      <Heading element={'h1'}>
        Available Assets.
      </Heading>
      <Form>
        <Heading
          element={'h4'}
        >
          Accounts
        </Heading>
        <FormRow>
          <Label flex={['0 0 100%', 1]}>
            <span>
              Checking and savings
              <ToolTip
                bubble={'The amount of money you have in your accounts gives us a better idea of your financial situation. It also helps us calculate a few key numbers on our end.'}
              >
                <Symbol type={'Info'} />
              </ToolTip>
            </span>
            <CurrencyInput
              allowDecimals={false}
              name={'amount-in-savings'}
              placeholder={'$1,000'}
              value={mortgageApplication.borrowerAmountInSavings}
              onChange={(v) => { updateMortgageApplication('borrowerAmountInSavings', capToMaxAmount(v || '')) }}
            />
            <ValidationError
              path={'borrowerAmountInSavings'}
              errors={errors}
            />
          </Label>
        </FormRow>
      </Form>
      <OptionalElement show={hasCoborrower}>
        <Form>
          <Heading element={'h4'}>
            Co-borrower Accounts
          </Heading>
          <FormRow>
            <Label flex={['0 0 100%', 1]}>
              <span>
                Checking and savings
                <ToolTip
                  bubble={'The amount of money you have in your accounts gives us a better idea of your financial situation. It also helps us calculate a few key numbers on our end.'}
                >
                  <Symbol type={'Info'} />
                </ToolTip>
              </span>
              <CurrencyInput
                allowDecimals={false}
                name={'coborrower-amount-in-savings'}
                placeholder={'$1,000'}
                value={mortgageApplication.coBorrowerAmountInSavings}
                onChange={(v) => { updateMortgageApplication('coBorrowerAmountInSavings', capToMaxAmount(v || '')) }}
              />
              <ValidationError
                path={'coBorrowerAmountInSavings'}
                errors={errors}
              />
            </Label>
          </FormRow>
        </Form>
      </OptionalElement>
    </>
  );
}
