import React from 'react';
import styled from 'styled-components';
import { Heading } from '@lightspeed/components/ui/atoms';
import { IconCircle } from '@lightspeed/components/ui/atoms/icon-circle';
import lock from '@lightspeed/assets/icons/lock.svg';
import credit from '@lightspeed/assets/icons/credit.svg';
import person from '@lightspeed/assets/icons/person.svg';
import bank from '@lightspeed/assets/icons/bank.svg';

export const IconBlurbs = () => (
  <Container>
    <BlurbHeader>
      <Heading element={'h3'}>
        What you get with fast track
      </Heading>
    </BlurbHeader>
    <BlurbRow>
      <Blurb>
        <BlurbIcon>
          <IconCircle
            icon={lock}
            background={'rgba(98, 54, 255, 0.1);'}
          />
        </BlurbIcon>
        <BlurbText>
          <Heading element={'h5'}>Immediate Rate Lock</Heading>
          <Copy>Don't wait. You can always tweak your loan prior to closing.</Copy>
        </BlurbText>
      </Blurb>
      <Blurb>
        <BlurbIcon>
          <IconCircle
            icon={credit}
            background={'rgba(62, 146, 247, 0.1);'}
          />
        </BlurbIcon>
        <BlurbText>
          <Heading element={'h5'}>Soft Credit Check</Heading>
          <Copy>Seeing if you qualify won't impact your credit score.</Copy>
        </BlurbText>
      </Blurb>
    </BlurbRow>
    <BlurbRow>
      <Blurb>
        <BlurbIcon>
          <IconCircle
            icon={person}
            background={'rgba(0, 220, 229, 0.1);'}
          />
        </BlurbIcon>
        <BlurbText>
          <Heading element={'h5'}>Expert Loan Advisor</Heading>
          <Copy>Text. Call. Email. Available 24/7. We're here to help whenever.</Copy>
        </BlurbText>
      </Blurb>
      <Blurb>
        <BlurbIcon>
          <IconCircle
            icon={bank}
            background={'rgba(0, 210, 0, 0.1);'}
          />
        </BlurbIcon>
        <BlurbText>
          <Heading element={'h5'}>24-hour Pre-qualification</Heading>
          <Copy>Or less. We'll reach out in a few minutes to get you pre-qualified.</Copy>
        </BlurbText>
      </Blurb>
    </BlurbRow>
  </Container>
);

const Container = styled.div`
  flex-direction: column;
  ${(props) => props.theme.media.desktop} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const BlurbRow = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.media.desktop} {
    display: flex;
    flex-direction: row;
  }
`;

const BlurbHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  ${(props) => props.theme.media.desktop} {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const Blurb = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;

  ${(props) => props.theme.media.desktop} {
    flex-direction: row;
    padding: 20px;
    flex-flow: row wrap;
  }
`;

const BlurbIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 15px;
`;

const BlurbText = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px
`;

const Copy = styled.div`
  font-family: ${(props) => props.theme.fonts.body};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.04em;
  color: #1C1C1E;
  margin-top: 10px;
`;
