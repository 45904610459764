import styled from 'styled-components';
import { LottieSource } from '@lightspeed/components/ui/atoms/lottie-source'
import LoadingDots from '@lightspeed/assets/lotties/LoadingDots.json';

export function LoadingModal() {
  return (
    <WhiteOverlayModal data-testid={'loading-modal'}>
      <LottieSource
        lottieJSONFile={LoadingDots}
        style={{
          left: '50%',
          position: 'absolute',
          top: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
          width: '10vmax',
        }}
      />
    </WhiteOverlayModal>
  );
}

const WhiteOverlayModal = styled.div`
  position: fixed;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.9);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
